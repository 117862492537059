import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Button from '@tidsbanken/components/buttons/Button';
import { usePhrases } from '@tidsbanken/phrases';
import { AxiosResponse } from 'axios';
import { getCheckListTemplates, postCheckList } from '../../helpers/dataFetcher';
import { Domain } from '../CheckList/api';
import { Heading } from '../ui';
import CheckListElement from '../ui/CheckListElement';
import './CheckListTemplate.less';

const CheckListTemplate = () => {
    const [checkLists, setCheckLists] = useState<Domain.Klasse[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [id, setId] = useState<string>('');
    const [creating, setCreating] = useState<boolean>(false);
    const p = usePhrases('hjem');

    const navigate = useNavigate();

    // Håndterer kall for å hente ut sjekklistemaler og setter state-en med resultatet
    const fetchData = () => {
        setLoading(true);
        getCheckListTemplates()
            .then((res: AxiosResponse) => {
                const values = res.data;
                setCheckLists(values as Domain.Klasse[]);
                setLoading(false);
            })
            .catch((e: Error) => {
                setErrorMessage(p('sl_t_msg_stamp_in'));
                console.error(e);
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleReturnClick = () => {
        navigate('/hjem');
    };

    // Håndterer callback fra ChecklistElement når opprett knappen blir trykket.
    // Denne gjør post-kall med klasseID-en og venter på respons OK, hvis OK blir man redirectet tilbake til hjem.
    const handleCreateCheckList = (id: string) => {
        setCreating(true);
        setId(id);
        postCheckList(id)
            .then((res) => {
                if (res.ok) {
                    setCreating(false);
                    navigate('/hjem');
                } else if (res.statusText === 'Forbidden') {
                    throw new Error(p('sl_t_err_access'));
                } else {
                    throw new Error('Noe gikk galt');
                }
            })
            .catch((e: Error) => {
                setErrorMessage(e.message);
                setId(id);
                console.error(e);
                setCreating(false);
            });
    };
    return (
        <div className="App">
            <div className="column">
                <div className="CheckListTemplateWrapper">
                    <Heading text={p('sl_t_choose')} />
                    <div className="ListWrapper">
                        {loading ? (
                            <div className="CheckListSkeletonWrapper">
                                <Stack spacing={1}>
                                    {Array.from(Array(5).keys()).map((_, index) => (
                                        <Skeleton key={index} height={25} variant="rectangular" />
                                    ))}
                                </Stack>
                            </div>
                        ) : (
                            <div className="List">
                                {errorMessage !== '' ? (
                                    <div className="NotCheckedIn">
                                        <img src={require('../../img/es_NoPlay.png')} />
                                        <span style={{ marginLeft: 10 }}>{errorMessage}</span>
                                    </div>
                                ) : checkLists.length === 0 ? (
                                    <span>{p('sl_t_no_templates')}</span>
                                ) : (
                                    checkLists.map((checkList: Domain.Klasse) => (
                                        <CheckListElement
                                            key={checkList.Id}
                                            id={checkList.Id}
                                            title={checkList.Tittel}
                                            description={checkList.Beskrivelse}
                                            tasks={checkList.Punkter}
                                            handleCreateCheckList={handleCreateCheckList}
                                            errorMessage={id === checkList.Id ? errorMessage : ''}
                                            creating={id === checkList.Id ? creating : false}
                                        />
                                    ))
                                )}
                            </div>
                        )}
                    </div>
                    <div className="BackButtonWrapper">
                        <Button
                            label={p('sl_t_back')}
                            kind={'info'}
                            icon={faChevronLeft}
                            leftAlignIcon={true}
                            flat={true}
                            onClick={handleReturnClick}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CheckListTemplate;
