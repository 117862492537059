import { faAddressCard } from '@fortawesome/pro-solid-svg-icons/faAddressCard';
import { faBirthdayCake } from '@fortawesome/pro-solid-svg-icons/faBirthdayCake';
import { faCreditCard } from '@fortawesome/pro-solid-svg-icons/faCreditCard';
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope';
import { faHome } from '@fortawesome/pro-solid-svg-icons/faHome';
import { faPhone } from '@fortawesome/pro-solid-svg-icons/faPhone';
import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { usePhrases } from '@tidsbanken/phrases';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { Ansatt } from '../../helpers/dataTypeHelper';

type Props = {
    employeeData: Ansatt;
};

const ProfileDetails = (props: Props) => {
    const p = usePhrases('hjem');

    const noDataMessage = <p className="NotRegistered">{p('pr_dtl_not_reg')}</p>;

    return (
        <div className="DetailContainer">
            {props.employeeData ? (
                <div>
                    <div className="DetailInfo">
                        <div className="DetailHeader">
                            <p>{p('pr_dtl_contact_info')}:</p>
                        </div>
                        <div className="DetailInfo">
                            <div className="DetailInfoLine">
                                <FontAwesomeIcon className="faIcon" icon={faPhone} />
                                <div>
                                    {props.employeeData.Mobil ? <p>{props.employeeData.Mobil}</p> : noDataMessage}
                                </div>
                            </div>
                            <div className="DetailInfoLine">
                                <FontAwesomeIcon className="faIcon" icon={faEnvelope} />
                                <div>
                                    {props.employeeData.Epost ? <p>{props.employeeData.Epost}</p> : noDataMessage}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="DetailInfo">
                        <div className="DetailHeader">
                            <p>{p('pr_dtl_addr')}:</p>
                        </div>
                        <div className="DetailInfo">
                            <div className="DetailInfoLine">
                                <div className="LeftSide">
                                    <FontAwesomeIcon className="faIcon" icon={faHome} />
                                </div>
                                <div>
                                    {props.employeeData.Adresse ||
                                    props.employeeData.Postnummer ||
                                    props.employeeData.Poststed ? (
                                        <div>
                                            {props.employeeData.Adresse ? (
                                                <p>{props.employeeData.Adresse}</p>
                                            ) : (
                                                noDataMessage
                                            )}
                                            {props.employeeData.Postnummer || props.employeeData.Poststed ? (
                                                <p>
                                                    {props.employeeData.Postnummer} {props.employeeData.Poststed}
                                                </p>
                                            ) : (
                                                noDataMessage
                                            )}
                                        </div>
                                    ) : (
                                        noDataMessage
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="DetailInfo">
                        <div className="DetailHeader">
                            <p>{p('pr_dtl_birth')}:</p>
                        </div>
                        <div className="DetailInfo">
                            <div className="DetailInfoLine">
                                <FontAwesomeIcon className="faIcon" icon={faBirthdayCake} />
                                <div>
                                    {props.employeeData.Fodt ? (
                                        <p>{format(parseISO(props.employeeData.Fodt), 'dd-MM-yyyy')}</p>
                                    ) : (
                                        noDataMessage
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="DetailInfo">
                        <div className="DetailHeader">
                            <p>{p('pr_dtl_pers_nr')}:</p>
                        </div>
                        <div className="DetailInfo">
                            <div className="DetailInfoLine">
                                <FontAwesomeIcon className="faIcon" icon={faAddressCard} />
                                <div>
                                    {props.employeeData.Personnummer ? (
                                        <p>{props.employeeData.Personnummer}</p>
                                    ) : (
                                        noDataMessage
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="DetailInfo">
                        <div className="DetailHeader">
                            <p>{p('pr_dtl_pay')}:</p>
                        </div>
                        <div className="DetailInfo">
                            <div className="DetailInfoLine">
                                <FontAwesomeIcon className="faIcon" icon={faCreditCard} />
                                <div>
                                    {props.employeeData.Lonnskonto ? (
                                        <p>{props.employeeData.Lonnskonto}</p>
                                    ) : (
                                        noDataMessage
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="DetailInfo">
                        <div className="DetailHeader">
                            <p>{p('pr_dtl_close_rel')}:</p>
                        </div>
                        <div className="DetailInfo">
                            <div className="DetailInfoLine">
                                <FontAwesomeIcon className="faIcon" icon={faUser} />
                                <div>
                                    {props.employeeData.IceNavn ? <p>{props.employeeData.IceNavn}</p> : noDataMessage}
                                </div>
                            </div>
                            <div className="DetailInfoLine">
                                <FontAwesomeIcon className="faIcon" icon={faPhone} />
                                <div>
                                    {props.employeeData.IceNr ? <p>{props.employeeData.IceNr}</p> : noDataMessage}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div>{p('pr_dtl_loading')}</div>
            )}
        </div>
    );
};

export default ProfileDetails;
