import { useEffect, useState } from 'react';
import { faCardsBlank } from '@fortawesome/pro-regular-svg-icons/faCardsBlank';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Button from '@tidsbanken/components/buttons/Button';
import { usePhrases } from '@tidsbanken/phrases';
import { getProfileDetails } from '../../helpers/dataFetcher';
import { Ansatt } from '../../helpers/dataTypeHelper';
import { Panel } from '../ui';
import ProfileCards from './ProfileCards';
import ProfileDetails from './ProfileDetails';
import ProfileDetailsEdit from './ProfileDetailsEdit';
import ProfileLanguageDropwdown from './ProfileLanguageDropdown';
import { ProfilePicture } from './ProfilePicture';
import ProfilePinEdit from './ProfilePinEdit';
import './Profile.less';

type Props = {
    handleNotLoggedIn: (isLoggedIn: boolean) => void;
};

const Profile = ({ handleNotLoggedIn }: Props) => {
    const [hasLoaded, setHasLoaded] = useState<boolean>(false);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [employeeData, setEmployeeData] = useState<Ansatt>();

    const p = usePhrases('hjem');

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        getProfileDetails()
            .then((data) => {
                setHasLoaded(true);
                setEmployeeData(data);
                setEmployeeData(data);
            })
            .catch((e) => {
                console.error(e);
                handleNotLoggedIn(false);
            });
    };

    const handleDetailsSave = (emp: Ansatt) => {
        setIsEditing(false);
        setEmployeeData(emp);
    };

    const handleClose = () => {
        setIsEditing(false);
    };

    return (
        <div className="App">
            <div className="column">
                <Panel>
                    {hasLoaded ? (
                        <div>
                            {employeeData && hasLoaded ? (
                                <div>
                                    <ProfilePicture employee={employeeData} isEditing={isEditing} />
                                    <div className="ProfileName">
                                        <p id="NameId">{employeeData.Navn}</p>
                                        <p id="TitleId">{employeeData.Tittel}</p>
                                    </div>

                                    <div className="ProfileDetails">
                                        {isEditing && employeeData ? (
                                            <div>
                                                <ProfileDetailsEdit
                                                    handleDetailsSave={handleDetailsSave}
                                                    handleClose={handleClose}
                                                    employeeData={employeeData}
                                                />
                                            </div>
                                        ) : (
                                            <div>
                                                <ProfileDetails employeeData={employeeData} />
                                            </div>
                                        )}
                                    </div>

                                    {isEditing ? (
                                        <div></div>
                                    ) : (
                                        <div className="DetailButtons">
                                            <Button
                                                onClick={(_) => setIsEditing(true)}
                                                label={p('pr_btn_edit')}
                                                kind="info"
                                                flat={true}
                                            />
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <Skeleton height={25} variant="rectangular" />
                            )}
                        </div>
                    ) : (
                        <div>
                            <div className="ProfileDetailsTopSkeleton">
                                <Stack spacing={1} display={'flex'} alignItems={'center'}>
                                    <Skeleton height={200} width={200} variant="circular" />
                                    <Skeleton height={34} width={200} variant="rectangular" />
                                    <Skeleton height={22} width={250} variant="rectangular" />
                                </Stack>
                            </div>
                            <div className="ProfileDetailsInfoSkeleton">
                                <Stack spacing={1} display={'flex'} alignItems={'center'}>
                                    {Array.from(Array(6).keys()).map((_, index) => (
                                        <Skeleton key={index} height={50} width={345} variant="rectangular" />
                                    ))}
                                </Stack>
                            </div>
                        </div>
                    )}
                </Panel>
            </div>

            <div className="column">
                <Panel>
                    <div className="HeaderYourCards">
                        <FontAwesomeIcon className={'HeaderYourCardsIcon'} icon={faCardsBlank} size={'1x'} />
                        <p className={'HeaderYourCardsText'}>{p('pr_my_cards').toUpperCase()}</p>
                    </div>
                    <hr className={'HorizontalRule'} />
                    <div>
                        {hasLoaded ? (
                            <ProfileCards id={employeeData?.Id} />
                        ) : (
                            <div className="SkeletonCards">
                                <Skeleton height={60} />
                            </div>
                        )}
                    </div>
                </Panel>

                <div className="HeaderLanguage">
                    <Panel>
                        <div>
                            {hasLoaded ? (
                                <ProfileLanguageDropwdown />
                            ) : (
                                <div>
                                    <Skeleton height={44} />
                                </div>
                            )}
                        </div>
                    </Panel>
                </div>

                <div className="EditPinPanel">
                    <Panel>
                        <div>
                            {hasLoaded ? (
                                <ProfilePinEdit />
                            ) : (
                                <div>
                                    <Skeleton height={44} />
                                </div>
                            )}
                        </div>
                    </Panel>
                </div>
            </div>

            <div className="column"></div>
        </div>
    );
};

export default Profile;
