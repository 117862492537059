import { useRef, useState } from 'react';
import React from 'react';
import { faStickyNote } from '@fortawesome/pro-solid-svg-icons/faStickyNote';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress, InputAdornment, Popover, TextField, TextFieldProps } from '@mui/material';
import EmojiPicker, { EmojiClickData, EmojiStyle } from 'emoji-picker-react';
import TbButton from './TbButton';
import './TextFieldNotat.less';

type Props = {
    loading?: boolean;
} & TextFieldProps;

const TextFieldNotat = ({ loading, ...attrs }: Props) => {
    const [showEmojiKeyboard, setShowEmojiKeyboard] = useState(false);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const rootRef = useRef<HTMLDivElement | null>(null);

    const handleShowEmojiKeyboard = () => {
        setShowEmojiKeyboard(!showEmojiKeyboard);
    };

    const handleClose = () => {
        setShowEmojiKeyboard(false);
    };

    const handleOnEmojiClick = (emojiObject: EmojiClickData) => {
        if (!inputRef.current) return;
        setShowEmojiKeyboard(false);
        const oldValue = inputRef.current.value;
        const caretPosition = inputRef.current.selectionEnd ?? inputRef.current.value.length;
        const newValue = oldValue.slice(0, caretPosition) + ' ' + emojiObject.emoji + oldValue.slice(caretPosition);
        attrs.onChange?.({ target: { value: newValue } } as React.ChangeEvent<HTMLInputElement>);
        if (!attrs.focused) {
            attrs.onFocus?.({} as React.FocusEvent<HTMLInputElement>);
        }
        inputRef.current.value = newValue;
    };

    return (
        <TextField
            {...attrs}
            ref={rootRef}
            inputRef={inputRef}
            variant="standard"
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <FontAwesomeIcon icon={faStickyNote} />
                    </InputAdornment>
                ),
                endAdornment: loading ? (
                    <CircularProgress sx={{ mr: 1 }} color="primary" size={20} />
                ) : (
                    <InputAdornment position="end">
                        <TbButton
                            className="emoji-button"
                            onClick={handleShowEmojiKeyboard}
                            variant="text"
                            color="info"
                            icon={showEmojiKeyboard ? '😄' : '😊'}
                            circular={false}
                        />
                        <Popover
                            open={showEmojiKeyboard}
                            anchorEl={rootRef?.current}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            onClose={handleClose}
                        >
                            <EmojiPicker
                                height={400}
                                emojiStyle={EmojiStyle.NATIVE}
                                emojiVersion="4.0"
                                onEmojiClick={handleOnEmojiClick}
                            />
                        </Popover>
                    </InputAdornment>
                ),
                sx: {
                    alignItems: attrs.multiline ? 'flex-start' : 'baseline',
                },
            }}
        />
    );
};

export default TextFieldNotat;
