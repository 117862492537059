import * as React from "react";
import "./Logo.less"

type Props = {
    className: string;
};

function Logo({ className }: Props)
{
    return (
        <svg
            id="tb-logo-colored"
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 87.83 87.83"
        >
            <title>
                Tidsbanken_logo_rgb
            </title>
            <polygon
                className="grey-base"
                points="43.92 71.25 49.28 87.83 38.55 87.83 43.92 71.25"
            />
            <polygon
                className="grey-base"
                points="43.91 16.58 38.55 0 49.28 0 43.91 16.58"
            />
            <polygon
                className="grey-base"
                points="30.25 67.59 26.6 84.63 17.31 79.27 30.25 67.59"
            />
            <polygon
                className="grey-base"
                points="57.58 20.24 61.23 3.2 70.52 8.56 57.58 20.24"
            />
            <polygon
                className="grey-base"
                points="16.58 43.92 0 49.28 0 38.55 16.58 43.92"
            />
            <polygon
                className="grey-base"
                points="71.25 43.91 87.83 38.55 87.83 49.28 71.25 43.91"
            />
            <polygon
                className="grey-base"
                points="20.24 30.25 3.2 26.6 8.56 17.31 20.24 30.25"
            />
            <polygon
                className="grey-base"
                points="67.59 57.58 84.63 61.23 79.27 70.52 67.59 57.58"
            />
            <polygon
                className="grey-base"
                points="30.25 20.25 17.31 8.56 26.6 3.2 30.25 20.25"
            />
            <polygon
                className="red-primary"
                points="83.83 25.15 80.14 18.74 4.05 62.53 6.05 66.01 40.89 45.96 62.69 83.7 69.09 80 49 45.2 83.83 25.15"
            />
        </svg>
    );
}

export default Logo;

export {
    Logo,
};
