import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import './HourlyBudget.less'

type Props = {
    text: string;
    progressFiller: number | null;
    rmvMargin: boolean;
};

const HourlyBudget = ({ text, progressFiller, rmvMargin }: Props) => {

    const ref = useRef<HTMLElement>(null);
    const [width, setWidth] = useState<number>(0);
    const [completed, setCompleted] = useState<number | null>(0);
    const [bgColor, setBgColor] = useState<string>("#2d9f79");

    useEffect(() => {
        setWidth(ref.current ? ref.current.offsetWidth : 0);
    }, [ref.current]);

    useEffect(() => {
        setCompleted(progressFiller);
        if (progressFiller) {
            if (progressFiller >= 75 && progressFiller <= 100) {
                setBgColor("#ffaa4d");
            } else if (progressFiller > 100) {
                setBgColor("#cb333b");
            } else {
                setBgColor("#2d9f79");
            }
        }
    }, [progressFiller])

    return (
        <div className={`HourlyBudgetWrapper ${rmvMargin ? "WithoutMargin" : ""}`}>
            <span ref={ref} className="HourlyBudgetText">{text}</span>
            {completed !== null ?
                <div className={`ProgressBarContainer`} style={{ width: width }}>
                    <div className="ProgressFiller" style={{ width: `${completed}%`, backgroundColor: `${bgColor}` }}></div>
                </div> : ""}
        </div>
    )
};

export default HourlyBudget;

