import * as React from 'react';
import './IpBlockedDisplay.less'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle} from "@fortawesome/pro-solid-svg-icons/faExclamationCircle";

type Props = {
    text: string,
    type: string
}

const IpBlockedDisplay = ({text, type}: Props) => {

    return (
        <div className={'IpBlockedDisplay ' + type}>
            <FontAwesomeIcon icon={faExclamationCircle} className={'ExclamationCircleIcon ' + type}/>
            <p className='IpBlockedMessage'>{text}</p>
        </div>
    )

};

export default IpBlockedDisplay;
