import * as React from 'react';
import Input from '@tidsbanken/components/Input';
import Button from '@tidsbanken/components/buttons/Button';
import { useState, FormEvent } from 'react';
import {apiURLAnsatte} from "../../helpers/dataFetcher";
import { Stemplekort } from '../../helpers/dataTypeHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faExclamationTriangle} from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle';
import { usePhrases } from '@tidsbanken/phrases';


type Props = {
    card?: Stemplekort;
    handlerEditSave: Function;
};

const ProfileCardEdit: React.FC<Props> = (props: Props) => {
    const [name, setName] = useState<string>(props.card && props.card.Navn || '');
    const [code, setCode] = useState<string>(props.card && props.card.Kode || '');
    const [errorMessage, setErrorMessage] = useState<string>('');

    const p = usePhrases("hjem");

    const handlerSave = () => {
        setErrorMessage('');
        if (props.card && props.card.Id) {
            // PATCH
            fetch(apiURLAnsatte + 'stemplekort/' + props.card.Id, {
                method: 'PATCH',
                credentials: "include",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    Navn: name,
                    Kode: code
                })
            })
                .then((res) => {
                    if (res.status === 200) {
                        return res.json();
                    } else if (res.status === 400) {
                        throw new Error(p("pr_card_in_use"))
                    }
                    throw new Error(p("pr_card_invalid"));
                })
                .then((data) => {
                    props.handlerEditSave(data);
                })
                .catch((err: Error) => {
                    setErrorMessage(err.message)
                    console.error(err)
                });
        } else {
            // POST
            let updatedCard = {
                Kode: code
            }
            fetch(apiURLAnsatte + 'stemplekort', {
                method: 'POST',
                credentials: "include",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(
                    updatedCard
                )
            })
                .then((res) => {
                    if (res.status === 201) {
                        return res.json();
                    } else if (res.status === 400) {
                        throw new Error(p("pr_card_in_use"))
                    }
                    throw new Error(p("pr_card_invalid"));
                })
                .then((data) => props.handlerEditSave(data))
                .catch((err: Error) => {
                    setErrorMessage(err.message)
                    console.error(err)
                });
        }
    }

    const nameChangeHandler = (e: FormEvent<HTMLInputElement>) => {
        setName(e.currentTarget.value)
    }

    const codeChangeHandler = (e: FormEvent<HTMLInputElement>) => {
        setCode(e.currentTarget.value);
    };

    const handleFocus = (e: FormEvent<HTMLInputElement> ) => e.currentTarget.select();

    return (
        <div className="CardContainer">
            <div className='ErrorMessage'>
                {errorMessage !== '' && (
                    <div>
                        <div className='LeftPinPopup'>
                            <FontAwesomeIcon
                                className="faIcon"
                                icon={faExclamationTriangle}
                            />
                        </div>
                        <div className='RightPinPopup'>
                            {errorMessage}
                        </div>
                    </div>
                )}
            </div>
            <div className="CardAlign">
                <div className="CardAlignLeft">
                    <div className="CardEdit">
                        <label htmlFor='kortkode'>{p("pr_card_code")}:</label>
                        <Input
                            onChange={codeChangeHandler}
                            id='kortkode'
                            value={code}
                            autoFocus
                            onFocus={handleFocus}
                        />
                    </div>
                </div>
                <div className="CardAlignRight">
                    <Button
                        onClick={(_) => handlerSave()}
                        disabled={props.card && code === ""}
                        className="CardsDeleteButton"
                        label={p("pr_card_btn_save")}
                        kind='success'
                        flat={true}
                    />
                </div>
            </div>
        </div>
    );
}

export default ProfileCardEdit;
