import * as React from 'react';
import './Panel.less';

type Props = {
    children: React.ReactNode;
    customClass?: string;
    onClick?: Function;
};

export function Panel(props: Props) {
    return (
        <div onClick={(e?: React.MouseEvent) => {
            props.onClick ? props.onClick() : "";
        }} className={'Panel ' + (props.customClass || "")}>
            <div className="PanelBody">
                {props.children}
            </div>
        </div>
    );
}
