import { faChevronRight } from "@fortawesome/pro-solid-svg-icons/faChevronRight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MenyPunkt } from "../helpers/dataTypeHelper";
import "./MenuList.less";

type Props = {
    menuList: MenyPunkt[];
};

const MenuList = ({ menuList }: Props) => {

    return (
        <div className='MenuList'>
            {menuList.map(item => (
                <a key={item.Id} href={item.Url} className="MenuListLinkWrapper">
                    <div className="Heading">
                        <div className="HeadingContent">
                            <span className="HeadingLinkWrapper" title={item.Navn}>
                                {item.Navn}
                                <FontAwesomeIcon
                                    className={"HeadingChevron MenuHeadingChevron"}
                                    icon={faChevronRight}
                                />
                            </span>
                        </div>
                    </div>
                </a>
            ))}
        </div>
    );
};

export default MenuList;
