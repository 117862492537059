import * as React from 'react';
import './ProfileLanguageDropdown.less';
import Button from '@tidsbanken/components/buttons/Button';
import { clearPhrases, WithPhrases } from "@tidsbanken/phrases";

type Language = {
    id: string,
    language: string
}

type LanguageRes = {
    system: string,
    user: string,
    combined: string
}

type State = {
    selectedLanguage: string,
    language: LanguageRes | null,
    show: boolean,
    currentLanguage: string
}

class ProfileLanguageDropwdown extends React.Component<{}, State> {
    constructor(props: {}) {
        super(props);
        this.state = {
            selectedLanguage: '',
            language: null,
            show: true,
            currentLanguage: ''
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCloseDiv = this.handleCloseDiv.bind(this);
    }

    handleChange(event: React.ChangeEvent<HTMLSelectElement>) {
        this.setState({ selectedLanguage: event.target.value });
    }

    handleSubmit(event: any) {
        fetch('https://api-phrases.tidsbanken.net/language/user', {
            credentials: 'include',
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                this.state.selectedLanguage
            )
        })
            .then((res) => {
                clearPhrases();
                res.json()
                window.location.reload()
            })
        event.preventDefault();
    }

    handleCloseDiv(event: any) {
        this.setState({ show: !this.state.show })
    }

    componentDidMount() {
        fetch('https://api-phrases.tidsbanken.net/language', { credentials: 'include' })
            .then(res => res.json())
            .then((data: LanguageRes) => {
                this.setState({ language: data })
                this.setState({ selectedLanguage: data.user != "" ? data.user : '' })
            })
            .catch(console.log)
    }

    render() {
        const languages = require("./languages.json") as Language[];

        return (
            <WithPhrases domain="hjem">
                {p => (
                    <div>
                        {
                            this.state.show ?
                                <div>
                                    <Button
                                        className="RegisterBtn"
                                        label={p("pr_language_btn_change")}
                                        flat={false}
                                        kind='info'
                                        onClick={() => { this.setState({ show: !this.state.show }) }}
                                    />

                                    {/*<input className="SubmitLanguage" type="submit" alt="Knapp for å endre språk i Tidsbanken" value="Endre språk"  onClick={() => { this.setState({ show: !this.state.show }) }} />*/}

                                </div>
                                :
                                <form onSubmit={this.handleSubmit}>
                                    <div className="LanguageForm">
                                        <label>
                                            {p("pr_language_pick_new")}
                                            <select onChange={this.handleChange} value={this.state.selectedLanguage}>
                                                <option value={''}>
                                                    Systemspråk ({this.state.language?.system})
                                                </option>

                                                {languages.map((item) => (
                                                    <option key={item.id} value={item.id}>
                                                        {item.language} ({item.id})
                                                    </option>
                                                ))}
                                            </select>

                                        </label>
                                    </div>
                                    <div className="LanguageButtons">
                                        <Button
                                            kind={'error'}
                                            label={p("pr_language_btn_cancel")}
                                            flat={true}
                                            onClick={this.handleCloseDiv}
                                        />
                                        <Button
                                            kind={'info'}
                                            label={p("pr_language_btn_save")}
                                            flat={false}
                                            onClick={this.handleSubmit}
                                        />
                                    </div>
                                </form>
                        }
                    </div>
                )}

            </WithPhrases>
        );
    }
}

export default ProfileLanguageDropwdown;
