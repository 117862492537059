import * as React from 'react';
import './RegisterDeviationButton.less';
import Button from '@tidsbanken/components/buttons/Button';
import { usePhrases } from '@tidsbanken/phrases';

type Props = {
    link: string;
};

const RegisterDeviationButton = ({ link }: Props) => {

    const p = usePhrases("hjem");

    return (
        <div className="RegisterWrapper">
            <a className="LinkWrapper" href={link}>
                <Button className="RegisterBtn"
                    kind={'info'}
                    label={p("ui_deviation_reg")}
                />
            </a>
        </div>
    )
};

export default RegisterDeviationButton;
