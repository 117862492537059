import Skeleton from '@mui/material/Skeleton';
import HourlyBudget from './ui/HourlyBudget';
import './ProjectInfo.less';

type Props = {
    ProjectInfo: {
        Id?: number;
        AnsattId?: number;
        FraKlokken?: string;
        ProsjektId?: string;
        Prosjekt?: {
            Id?: string;
            Navn?: string;
            Notat?: string;
        };
        ProsjektLinjeId?: string;
        ProsjektLinje?: {
            ProsjektId?: string;
            ProsjektLinjeId?: string;
            Navn?: string;
        };
        AktivitetId?: string;
        Aktivitet?: {
            Id?: string;
            Navn?: string;
        };
    };
    hourlyBudget: string;
    progressFiller: number | null;
    loading: boolean;
};

const ProjectInfo = ({ ProjectInfo, hourlyBudget, progressFiller, loading }: Props) => {
    let classNameProjectLine;
    let classNameActivity;
    let rmvMargin = false;

    if (ProjectInfo.Prosjekt && ProjectInfo.ProsjektLinje && ProjectInfo.Aktivitet) {
        classNameProjectLine = 'ProjectLineWrapper';
        classNameActivity = 'ActivityWrapper';
    } else if (!ProjectInfo.ProsjektLinje && ProjectInfo.Prosjekt && ProjectInfo.Aktivitet) {
        classNameProjectLine = 'ProjectLineWrapperEmpty';
        classNameActivity = 'ActivityWrapperAlone';
    } else if (!ProjectInfo.Aktivitet && ProjectInfo.Prosjekt && ProjectInfo.ProsjektLinje) {
        classNameProjectLine = 'ProjectLineWrapperWithProject';
    } else if (!ProjectInfo.Prosjekt && !ProjectInfo.ProsjektLinje && ProjectInfo.Aktivitet) {
        classNameActivity = 'ActivityWrapperAlone';
        rmvMargin = true;
    }

    return (
        <div className={!ProjectInfo.ProsjektLinje && !ProjectInfo.Aktivitet ? 'ProjectAlone' : 'ProjectInfo'}>
            <p>{ProjectInfo.Prosjekt ? `${ProjectInfo.Prosjekt.Id} ${ProjectInfo.Prosjekt.Navn}` : ''}</p>
            <div className={classNameProjectLine}>
                <p>
                    {ProjectInfo.ProsjektLinje
                        ? `${ProjectInfo.ProsjektLinje.ProsjektLinjeId} ${ProjectInfo.ProsjektLinje.Navn}`
                        : ''}
                </p>
                <div className={classNameActivity}>
                    <p>{ProjectInfo.Aktivitet ? `${ProjectInfo.Aktivitet.Id} ${ProjectInfo.Aktivitet.Navn}` : ''}</p>
                    {loading ? (
                        <div style={{ marginLeft: rmvMargin ? 0 : 20 }}>
                            <Skeleton height={10} variant="rectangular" width={'50%'} />
                        </div>
                    ) : hourlyBudget !== '' ? (
                        <HourlyBudget text={hourlyBudget} progressFiller={progressFiller} rmvMargin={rmvMargin} />
                    ) : (
                        ''
                    )}
                </div>
            </div>
        </div>
    );
};

export default ProjectInfo;
