import { useEffect, useRef, useState } from 'react';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import { faGauge } from '@fortawesome/pro-solid-svg-icons/faGauge';
import { faGaugeMax } from '@fortawesome/pro-solid-svg-icons/faGaugeMax';
import { faGaugeMed } from '@fortawesome/pro-solid-svg-icons/faGaugeMed';
import { faGaugeMin } from '@fortawesome/pro-solid-svg-icons/faGaugeMin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Collapse from '@mui/material/Collapse';
import isYesterday from 'date-fns/isYesterday';
import { getOpatSumDepartmentTurnover, omsetningsUrl, planleggingUrl, timelinjeUrl } from '../helpers/dataFetcher';
import {
    ApiMe,
    ComponentSettings,
    DisplayOpatForDepartment,
    PlannedOpat,
    PlannedProductiveHours,
    Timeantall,
} from '../helpers/dataTypeHelper';
import Spinner from './ui/Spinner';
import { Panel } from './ui';
import './PulsPanel.less';

const GaugeIcon = ({ percentage }: { percentage: number }) => {
    const max = percentage >= 100;
    const mid = percentage >= 86 && percentage < 100;
    const min = percentage > 0 && percentage < 86;

    return (
        <FontAwesomeIcon
            className={['opat-icon', max ? 'gauge-max' : '', mid ? 'gauge-mid' : '', min ? 'gauge-min' : '']
                .join(' ')
                .trim()}
            icon={(max && faGaugeMax) || (mid && faGaugeMed) || (min && faGaugeMin) || faGauge}
            title="0-85%: Rød | 86-99%: Gul  | 100%: Grønn | Ingen data: Grå"
        />
    );
};

type Props = {
    opatDepartmentId?: string;
    apiMe?: ApiMe;
    opatSettings?: ComponentSettings;
    displayOpatForDepartmentData: DisplayOpatForDepartment;
};

const PulsPanel = ({ apiMe, opatSettings, displayOpatForDepartmentData }: Props) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [isOpen, setIsOpen] = useState(false);
    const [plannedOpat, setPlannedOpat] = useState<number | undefined>(0);
    const [liveOpat, setLiveOpat] = useState<number | undefined>(0);
    const [lastPlannedOpat, setLastPlannedOpat] = useState<number | undefined>(undefined);
    const [lastPlannedOpatDate, setLastPlannedOpatDate] = useState<Date | undefined>(undefined);
    const [lastOpat, setLastOpat] = useState<number | undefined>(undefined);
    const [lastPercentage, setLastPercentage] = useState<number>(0);
    const [livePercentage, setLivePercentage] = useState<number>(0);
    const [opatDepartmentId, setOpatDepartmentId] = useState<string | undefined>(undefined);
    const hasRun = useRef(false);
    const displayLiveOpat = displayOpatForDepartmentData.DepartmentLiveOpat;
    const opatNumber = plannedOpat === undefined || isNaN(plannedOpat) ? '—' : plannedOpat + ',-';
    const liveOpatNumber = liveOpat === Infinity || isNaN(Number(liveOpat)) ? '—' : liveOpat + ',-';
    const lastPlannedOpatNumber =
        lastPlannedOpat === undefined || lastPlannedOpat === 0 || isNaN(lastPlannedOpat) ? '—' : lastPlannedOpat + ',-';
    const lastOpatNumber = lastOpat === undefined || lastOpat === 0 || isNaN(lastOpat) ? '—' : lastOpat + ',-';

    const getLiveOpatSumDepartmentTurnover: (departmentId: string) => Promise<PlannedOpat> = async (
        departmentId: string
    ) =>
        await (
            await fetch(`${omsetningsUrl}/opat/SumAvdelinger/${departmentId}?ipType=I`, { credentials: 'include' })
        ).json();

    const getPlannedHours: (
        departmentId: string,
        startDate: string | null,
        endDate: string | null
    ) => Promise<PlannedProductiveHours> = async (
        departmentId: string,
        startDate: string | null,
        endDate: string | null
    ) =>
        startDate && endDate
            ? await (
                  await fetch(
                      `${planleggingUrl}/PlannedProductiveHours/${departmentId}?startDate=${startDate}&endDate=${endDate}`,
                      { credentials: 'include' }
                  )
              ).json()
            : await (
                  await fetch(`${planleggingUrl}/PlannedProductiveHours/${departmentId}`, { credentials: 'include' })
              ).json();

    const getTimeantall: (
        departmentId: string,
        startDate: string | null,
        endDate: string | null
    ) => Promise<Timeantall> = async (departmentId: string, startDate: string | null, endDate: string | null) =>
        startDate && endDate
            ? await (
                  await fetch(
                      `${timelinjeUrl}/Opat/Timeantall/${departmentId}?startDate=${startDate}&endDate=${endDate}`,
                      { credentials: 'include' }
                  )
              ).json()
            : await (await fetch(`${timelinjeUrl}/Opat/Timeantall/${departmentId}`, { credentials: 'include' })).json();

    const getLiveTimeantall: (departmentId: string) => Promise<Timeantall> = async (departmentId: string) =>
        await (
            await fetch(`${timelinjeUrl}/Opat/Timeantall/${departmentId}?live=true`, { credentials: 'include' })
        ).json();

    const fetchLastOpat = async (_opatDepartmentId: string) => {
        try {
            // SJÅ PÅ DITTE

            const res = await getOpatSumDepartmentTurnover(_opatDepartmentId, true, undefined, undefined);
            if (res.StartDate === undefined || res.EndDate === undefined) {
                // ??
            } else {
                setLastPlannedOpatDate(new Date(res.StartDate));
                const turnOver = res.TotaleOmsetning;
                const startDate = res.StartDate;
                const endDate = res.EndDate;
                const [lastPlannedTurnover, lastPlannedHours, timeantall] = await Promise.all([
                    getOpatSumDepartmentTurnover(_opatDepartmentId, false, startDate, endDate),
                    getPlannedHours(_opatDepartmentId, startDate, endDate),
                    getTimeantall(_opatDepartmentId, startDate, endDate),
                ]);
                setLastPlannedOpat(
                    lastPlannedHours.TotalPlannedProductiveHours === 0
                        ? undefined
                        : Math.ceil(lastPlannedTurnover.TotaleOmsetning / lastPlannedHours.TotalPlannedProductiveHours)
                );
                setLastOpat(
                    timeantall.TotaleTimeantall === 0 ? undefined : Math.ceil(turnOver / timeantall.TotaleTimeantall)
                );
                setLastPercentage(
                    (Math.ceil(turnOver / timeantall.TotaleTimeantall) /
                        Math.ceil(lastPlannedTurnover.TotaleOmsetning / lastPlannedHours.TotalPlannedProductiveHours)) *
                        100
                );
            }
        } catch (error) {
            console.log(error);
        }
    };

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    const fetchOpat = async (_opatDepartmentId: string) => {
        setLoading(true);
        try {
            const [plannedTurnover, plannedHours] = await Promise.all([
                getOpatSumDepartmentTurnover(_opatDepartmentId, false, undefined, undefined),
                getPlannedHours(_opatDepartmentId, null, null),
            ]);

            const turnOver = plannedTurnover.TotaleOmsetning;
            const hours = plannedHours.TotalPlannedProductiveHours;

            if (turnOver === 0 && hours === 0) {
                setPlannedOpat(undefined);
                setLoading(false);
                return;
            } else if (hours === 0) {
                setPlannedOpat(undefined);
                setLoading(false);

                return;
            } else if (turnOver === 0) {
                setPlannedOpat(0);
                setLoading(false);

                return;
            } else {
                setPlannedOpat(Math.ceil(turnOver / hours));
                setLoading(false);
            }
        } catch (error) {
            console.error(error);
        }
    };
    const fetchLiveOpat = async (_opatDepartmentId: string) => {
        try {
            const [liveTurnover, liveHours] = await Promise.all([
                getLiveOpatSumDepartmentTurnover(_opatDepartmentId),
                getLiveTimeantall(_opatDepartmentId),
            ]);
            const turnOver = liveTurnover.TotaleOmsetning;
            const hours = liveHours.TotaleTimeantall;
            setLiveOpat(Math.ceil(turnOver / hours));
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (opatDepartmentId === undefined) {
            setOpatDepartmentId(apiMe?.Hovedavdeling);
        }
        if (opatDepartmentId !== '') {
            if (opatDepartmentId) {
                displayLiveOpat ? fetchLiveOpat(opatDepartmentId) : '';
                fetchOpat(opatDepartmentId);
            } else {
                if (apiMe) {
                    displayLiveOpat ? fetchLiveOpat(apiMe.Avdeling) : '';
                    fetchOpat(apiMe.Avdeling);
                }
            }
        }
    }, [apiMe]);

    useEffect(() => {
        if (plannedOpat !== undefined && liveOpat !== undefined) setLivePercentage((liveOpat / plannedOpat) * 100);
    }, [plannedOpat, liveOpat]);

    useEffect(() => {
        if (isOpen && lastOpat === undefined && lastPlannedOpat === undefined && !hasRun.current) {
            if (opatDepartmentId !== '') {
                if (opatDepartmentId) {
                    displayLiveOpat ? fetchLiveOpat(opatDepartmentId) : '';
                    fetchLastOpat(opatDepartmentId);
                } else {
                    if (apiMe) {
                        displayLiveOpat ? fetchLiveOpat(apiMe.Avdeling) : '';
                        fetchLastOpat(apiMe.Avdeling);
                    }
                }
            }
            hasRun.current = true;
        }
    }, [toggle]);

    const opatString = displayLiveOpat ? 'Dagens OPAT' : 'Planlagt OPAT ';

    return (
        <div className="PulsPanelWrapper">
            <Panel customClass="PulsPanel" onClick={toggle}>
                <div className={displayLiveOpat ? 'lopat-container' : 'popat-container'}>
                    <span className="opat-text">
                        <strong>{opatString}</strong>
                    </span>
                    <div className="opat-right">
                        <div className="opat-number">
                            <strong>
                                {loading ? (
                                    <span className="spinnerWrapper">
                                        <Spinner width="6%" />
                                    </span>
                                ) : displayLiveOpat ? (
                                    liveOpatNumber
                                ) : (
                                    opatNumber
                                )}
                            </strong>
                        </div>
                    </div>
                    {displayLiveOpat ? <div className="lopat">{GaugeIcon({ percentage: livePercentage })}</div> : ''}
                    <FontAwesomeIcon className={'expand-icon ' + (isOpen ? 'rotated' : 'fixed')} icon={faChevronDown} />
                </div>
                {displayLiveOpat ? (
                    <div className="lopat-popat-container">
                        <span className="opat-text">
                            <strong>Planlagt OPAT</strong>
                        </span>
                        <div className="opat-right-expanded">
                            <div className="opat-number">
                                <strong>
                                    {loading ? (
                                        <span className="spinnerWrapper">
                                            <Spinner width="6%" />
                                        </span>
                                    ) : (
                                        opatNumber
                                    )}
                                </strong>
                            </div>
                        </div>
                    </div>
                ) : null}

                <Collapse in={isOpen} className="opat-info" unmountOnExit>
                    {!displayLiveOpat || displayLiveOpat === undefined ? (
                        <p className="opat-descr">
                            OPAT står for omsetning per arbeidstime. Tallet over viser planlagt OPAT for i dag .
                        </p>
                    ) : null}
                    <hr />
                    <div className="opat-stats">
                        {lastPlannedOpatDate !== undefined ? (
                            <div className="opat-names">
                                <strong>
                                    <p className="opat-day">
                                        {lastPlannedOpatDate && !isYesterday(lastPlannedOpatDate)
                                            ? lastPlannedOpatDate
                                                  .toLocaleDateString('nb-NO', {
                                                      weekday: 'long',
                                                      year: '2-digit',
                                                      month: '2-digit',
                                                      day: '2-digit',
                                                  })
                                                  .replace(/\w\S*/g, function (txt) {
                                                      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                                                  })
                                            : lastPlannedOpatDate && isYesterday(lastPlannedOpatDate)
                                            ? 'I går'
                                            : 'Ingen data siste 14 dager'}
                                    </p>
                                </strong>
                                <strong>
                                    <p>OPAT</p>
                                </strong>
                                <strong>
                                    <p>Planlagt OPAT</p>
                                </strong>
                            </div>
                        ) : (
                            <strong> Ingen registrert omsetning siste 14 dager</strong>
                        )}
                        {lastPlannedOpatDate !== undefined ? (
                            <div className="opat-values">
                                <div> </div>
                                <div className="opat-last-opat">
                                    <strong>
                                        <p>{lastOpatNumber}</p>
                                    </strong>
                                </div>

                                <div className="opat-last-number">
                                    <strong>
                                        <p>{lastPlannedOpatNumber}</p>
                                    </strong>
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                        <div className="opat-gauge">{GaugeIcon({ percentage: lastPercentage })}</div>
                    </div>
                    {lastPlannedOpatDate !== undefined ? (
                        <div className="last-opat-descr">
                            <p>
                                Tallene over viser den faktiske OPAT-en og den planlagte OPAT-en for forrige dag med
                                registrert omsetning <p>innen de siste 14 dagene.</p>
                            </p>
                        </div>
                    ) : (
                        ''
                    )}
                </Collapse>
            </Panel>
        </div>
    );
};

export default PulsPanel;
