import { RefObject } from 'react';
import { useEventListener } from './use-eventListener';

type Handler = (event: MouseEvent) => void;

export function useOnClickOutside<T extends HTMLElement = HTMLElement>(
    ref: RefObject<T>,
    handler: Handler,
    mouseEvent: 'mousedown' | 'mouseup' = 'mousedown'
): void {
    useEventListener(mouseEvent, (event) => {
        const el = ref?.current;

        if (!el || el.contains(event.target as Node)) {
            return;
        }

        handler(event);
    });
}

export function useOnClickOutsideCustom<T extends HTMLElement = HTMLElement>(
    ref1: RefObject<T>,
    handler: Handler,
    mouseEvent: 'mousedown' | 'mouseup' = 'mousedown'
): void {
    useEventListener(mouseEvent, (event) => {
        const el = ref1?.current;

        if (!el || el.contains(event.target as Node)) {
            return;
        }

        if (event.target instanceof HTMLElement) {
            if (event.target.className.includes('__EmojiPicker__')) {
                return;
            }
        }

        handler(event);
    });
}
