import * as React from 'react';
import { useEffect, useState } from 'react';
import Button from '@tidsbanken/components/buttons/Button';
import { usePhrases } from '@tidsbanken/phrases';
import { apiURLAnsatte } from '../../helpers/dataFetcher';
import { Stemplekort } from '../../helpers/dataTypeHelper';
import { ProfileCard } from './ProfileCard';
import ProfileCardEdit from './ProfileCardEdit';

type State = {
    addCardClicked: boolean;
    isButtonDisabled: boolean;
    isEditing: boolean;
};

type Props = {
    id?: number;
};

const ProfileCards = (props: Props) => {
    const [stampCards, setStampCards] = useState<Stemplekort[]>([]);
    const [addCardClicked, setAddCardClicked] = useState<boolean>(false);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const [id, setId] = useState<number>(-1);
    const p = usePhrases('hjem');

    useEffect(() => {
        fetchData();
    }, [id]);

    const editingFunction = () => {
        setAddCardClicked(true);
        setIsDisabled(true);
        setIsEditing(true);
    };

    const notEditingFunction = () => {
        setAddCardClicked(false);
        setIsDisabled(false);
        setIsEditing(false);
    };

    const fetchData = () => {
        setId(props.id ? props.id : -1);

        if (id >= 0) {
            fetch(apiURLAnsatte + `stemplekort?Filter=AnsattId eq ${id}`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            })
                .then((res) => {
                    if (res.status === 200) {
                        return res.json();
                    }
                    throw new Error(p('pr_card_invalid'));
                })
                .then((data) => {
                    setStampCards(data.value);
                })
                .catch((err: Error) => {
                    console.error(err);
                });
        }
    };

    const deleteHandler = (id?: number) => {
        if (!id) {
            return;
        }

        const response = confirm(p('pr_card_q_delete'));
        if (response) {
            fetch(apiURLAnsatte + 'stemplekort/' + id, {
                method: 'DELETE',
                credentials: 'include',
            })
                .then(() => {
                    const index = stampCards.findIndex((stampCard: Stemplekort) => stampCard.Id === id);
                    if (index > -1) {
                        const newStampCards = [...stampCards];
                        newStampCards.splice(index, 1);
                        setStampCards(newStampCards);
                    }
                })
                .catch(() => {
                    // Feilmelding: f.eks. feil ved sletting av kort. Generisk.
                });
        }
    };

    const handlerEditSave = (card: Stemplekort) => {
        const index = stampCards.findIndex((stampCard: Stemplekort) => stampCard.Id === card.Id);

        let newList: Stemplekort[] = stampCards;

        if (index > -1) {
            newList = Object.assign([], newList, { [index]: card });
        } else {
            newList.push(card);
        }

        notEditingFunction();
        setStampCards(newList);
    };

    return (
        <div className="CardsContainer">
            {stampCards.length > 0 || isEditing ? (
                <div>
                    {stampCards.map((stampCard: Stemplekort) => (
                        <ProfileCard
                            handlerEditSave={(card: Stemplekort) => handlerEditSave(card)}
                            deleteHandler={(_: any) => deleteHandler(stampCard.Id)}
                            key={stampCard.Id}
                            card={stampCard}
                        />
                    ))}
                </div>
            ) : (
                <p className="NoCards">{p('pr_card_no_cards')}</p>
            )}
            {addCardClicked ? <ProfileCardEdit handlerEditSave={(card: Stemplekort) => handlerEditSave(card)} /> : ''}

            <div className="CardButtons">
                {isEditing ? (
                    <div>
                        <Button
                            onClick={(e?: React.MouseEvent) => {
                                const target = e?.currentTarget as HTMLElement;
                                target.blur();
                                notEditingFunction();
                            }}
                            label={p('pr_btn_cancel')}
                            kind="error"
                            flat={true}
                        />
                    </div>
                ) : (
                    <div>
                        <Button
                            onClick={(e?: React.MouseEvent) => {
                                const target = e?.currentTarget as HTMLElement;
                                target.blur();
                                editingFunction();
                            }}
                            disabled={isDisabled}
                            label={p('pr_card_btn_add')}
                            flat={true}
                            kind="info"
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProfileCards;
