import * as React from 'react';
import { Ansatt } from '../../helpers/dataTypeHelper'
import { ChangeEvent, useState, useEffect } from 'react';
import ProfilePictureEdit from './ProfilePictureEdit';
import { apiURLAnsatte } from '../../helpers/dataFetcher';
import Button from '@tidsbanken/components/buttons/Button';
import './ProfilePicture.less';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons/faExclamationTriangle";
import { usePhrases } from '@tidsbanken/phrases';

type Props = {
    employee: Ansatt;
    isEditing: boolean;
};

export function ProfilePicture(props: Props) {
    const [isEditingProfilePicture, setIsEditingProfilePicture] = useState<boolean>(false);
    const [imgURL, setImgURL] = useState<string>(props.employee.Filnavn || '');
    const [originalImgURL, setOriginalImgURL] = useState<string>(props.employee.Filnavn || '');
    const [sizeError, setSizeError] = useState<boolean>(false);
    const firstName = props.employee.Fornavn;
    const lastName = props.employee.Etternavn;
    const p = usePhrases("hjem");

    useEffect(() => {
        const localImgURL: boolean = imgURL.startsWith('data');

        if (localImgURL) {
            setIsEditingProfilePicture(true);
        }
    }, [imgURL]);

    useEffect(() => {
        if (!props.isEditing) {
            setImgURL(originalImgURL);
        }
    }, [props.isEditing])

    const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
        let file = e.target.files ? e.target.files[0] : undefined;
        if (file) {
            let reader = new FileReader();
            reader.onloadend = () => {
                setImgURL(reader.result as string);
            };
            reader.readAsDataURL(file);
        }
    }

    const deleteProfilePicture = async (e?: React.MouseEvent, askBeforeDelete?: boolean) => {
        let guid: string = '';

        if (askBeforeDelete) {
            if (!window.confirm(p("pr_pic_u_sure"))) {
                return;
            }

            guid = getImageGuid(imgURL);
        } else {
            if (!originalImgURL) {
                return;
            }

            guid = getImageGuid(originalImgURL);
        }

        await deleteImageByGuid(guid, askBeforeDelete);
    }

    const getImageGuid = (imageUrl: string): string => {
        try {
            return imageUrl.substring(imageUrl.lastIndexOf("/") + 1, imageUrl.length);
        } catch {
            console.error("Feilet på å finne guid til bildet.");
            return '';
        }
    }

    const deleteImageByGuid = async (guid: string, askBeforeDelete: boolean = false) => {
        await fetch(apiURLAnsatte + 'profilbilde/' + guid, {
            method: 'DELETE',
            credentials: 'include'
        })
            .then(() => {
                if (askBeforeDelete) {
                    setImgURL('');
                    setOriginalImgURL('');
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }

    const handleCancel = () => {
        setImgURL(originalImgURL || '');
        setIsEditingProfilePicture(false);
    }


    const handleSizeError = (errorVal: boolean) => {
        setSizeError(errorVal);
    };

    function stringToColour(str: string) {
        str = str.toUpperCase().replace(/\s/g, '');
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        let colour = '#';
        const saturation = 0.65; // Saturation factor (0-1)
        const lightness = 0.8; // Lightness factor (0-1)
        for (let i = 0; i < 3; i++) {
            const baseValue = (hash >> (i * 8)) & 0xFF;
            const newValue = Math.floor((baseValue * saturation) + (lightness * 255 * (1 - saturation)));
            colour += newValue.toString(16).padStart(2, '0').slice(-2);
        }
        return colour;
    }

    return (
        <div className={'PictureContainer'}>
            {sizeError ? <div className='ErrorContainer'>
                <div className='ErrorIconContainer'>
                    <FontAwesomeIcon
                        className="faIcon"
                        icon={faExclamationTriangle}
                    />
                </div>
                <div className='ErrorTextContainer'>
                    <span>{p("pr_pic_too_small")}</span>
                </div>
            </div> : ""}

            <div className='ContentContainer'>
                {props.isEditing && isEditingProfilePicture ? (
                    <ProfilePictureEdit
                        img={imgURL}
                        handleEditFinished={(_imgURL?: string) => {
                            setIsEditingProfilePicture(false);
                            if (!_imgURL) {
                                setImgURL(originalImgURL);
                            } else {
                                setImgURL(_imgURL);
                                setOriginalImgURL(_imgURL);
                            }
                        }}
                        deleteProfilePicture={(e: any) => deleteProfilePicture(e)}
                        handleCancel={handleCancel}
                        triggerError={handleSizeError}
                    />
                ) : (
                    <div>
                        {props.isEditing && (
                            <div>
                                <div className="ImageInputContainer">
                                    <label
                                        className="ImageLabel"
                                        htmlFor="ImageInput"
                                    >
                                        <div className="EditImageButton">
                                            <span>{p("pr_pic_change")}</span>
                                        </div>
                                    </label>
                                    <input
                                        id="ImageInput"
                                        type="file"
                                        name="file"
                                        accept="image/*"
                                        onChange={handleImageChange}
                                    />
                                </div>
                            </div>
                        )}
                        <div className={'CircularImage'}>
                            {imgURL ? (
                                <img src={imgURL} alt='Profilbilde til innlogget bruker. ' />
                            ) : (
                                <div className="NoUserImage" style={{ backgroundColor: stringToColour(firstName + lastName) }}>
                                    {firstName.slice(0, 1).toUpperCase()}
                                    {lastName.slice(0, 1).toUpperCase()}
                                </div>
                            )}
                        </div>
                    </div>
                )}
                {props.isEditing && !isEditingProfilePicture &&
                    <Button
                        className="DeleteImageButton"
                        kind={'error'}
                        flat={true}
                        label={p("pr_pic_del")}
                        onClick={(e?: React.MouseEvent) => deleteProfilePicture(e, true)}
                        disabled={imgURL.length < 1}
                    />
                }
            </div>
        </div>
    );
}
