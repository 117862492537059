import React, { CSSProperties, useEffect, useState } from "react";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons/faTriangleExclamation";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons/faCircleExclamation";
import { faSpinner } from "@fortawesome/free-solid-svg-icons/faSpinner";
import { faPartyHorn } from "@fortawesome/pro-solid-svg-icons/faPartyHorn";
import { faCheck } from "@fortawesome/pro-regular-svg-icons/faCheck";
import { faXmark } from "@fortawesome/pro-solid-svg-icons/faXmark";
import "./style.less";

export type Kind = "info" | "success" | "warning" | "error" | "celebration";
export type Size = "big" | "small" | "snack" | "component";

/**
 * Properties for the Button component
 *
 * @interface Props
 */
export interface Props {
    /** Event handler for the onClick event */
    onActionClick?: (event: React.MouseEvent<HTMLSpanElement>) => void;
    /** DOM class name */
    className?: string;
    /** Icon from import to be included in the notification */
    icon?: IconDefinition;
    /** Hides icon */
    hideIcon?: boolean;
    /** Spins icon and adds default spinner icon */
    spin?: boolean;
    /** Label and text for the notification */
    label?: string;
    /** Label and text for action button in the notification */
    actionLabel?: string;
    /** Size of the notification */
    size?: Size;
    /** Kind of notification, different styling */
    kind?: Kind;
    close?: boolean;
    onClose?: (event: React.MouseEvent<HTMLSpanElement>) => void;
}

/**
 * Displays a notification component.
 * @param props.onClick Event handler for the onClick event.
 * @param props.className Class of the component.
 * @param props.kind Type of notification you would like to have. "info", "success", "warning", "error".
 * @param props.size Size of the notification. Big, small, snackbar or component.
 * @param props.icon Use to change default icon (most kinds have different icons as default), expects an imported FA icon
 * @param props.hideIcon Hides icon, shown by default
 * @param props.spin Makes icon spin also adds default spinner icon
 * @param props.actionLabel Text inside the action button in the notification.
 * @param props.close add close (X) button | true | false
 * @param props.onClose handler for close (X) button click
 */

const Notification: React.FunctionComponent<Props> = ({
    onActionClick,
    className = "",
    icon,
    hideIcon,
    spin,
    label = "",
    actionLabel,
    size = "big",
    kind = "info",
    close = false,
    onClose,
}: Props) => {
    const [isSnackbar, setIsSnackbar] = useState<boolean>(false);
    const [isComponentNotification, setIsComponentNotification] = useState<boolean>(false);
    const [hasActionLabel, setHasActionLabel] = useState<boolean>(true);
    const [hasCloseButton, setHasCloseButton] = useState<boolean>(false);
    const [shouldHideIcon, setShouldHideIcon] = useState<boolean>(false);
    const [shouldSpin, setShouldSpin] = useState<boolean>(false);
    const [defaultIcon, setDefaultIcon] = useState<IconDefinition>();

    useEffect(() => {
        if (size === "snack") {
            setIsSnackbar(true);
        } else {
            setIsSnackbar(false);
        }
        if (size === "component") {
            setIsComponentNotification(true);
        } else {
            setIsComponentNotification(false);
        }
        if (actionLabel === "") {
            setHasActionLabel(false);
        } else {
            setHasActionLabel(true);
        }
        if (typeof close === "boolean") {
            setHasCloseButton(close);
        }
        if (typeof hideIcon === "boolean") {
            setShouldHideIcon(hideIcon);
        }
        if (typeof spin === "boolean") {
            setShouldSpin(spin);
        }
    }, [size, kind, actionLabel, close, hideIcon, spin]);

    useEffect(
        function defaultIcon() {
            if (kind === "celebration" && !icon) {
                if (!spin) {
                    setDefaultIcon(faPartyHorn);
                } else {
                    setDefaultIcon(faSpinner);
                }
            }
            if (kind === "error" && !icon) {
                if (!spin) {
                    setDefaultIcon(faTriangleExclamation);
                } else {
                    setDefaultIcon(faSpinner);
                }
            }
            if (kind === "info" && !icon) {
                if (!spin) {
                    setDefaultIcon(faCircleExclamation);
                } else {
                    setDefaultIcon(faSpinner);
                }
            }
            if (kind === "success" && !icon) {
                if (!spin) {
                    setDefaultIcon(faCheck);
                } else {
                    setDefaultIcon(faSpinner);
                }
            }
            if (kind === "warning" && !icon) {
                if (!spin) {
                    setDefaultIcon(faCircleExclamation);
                } else {
                    setDefaultIcon(faSpinner);
                }
            }
            if (icon) {
                if (!spin || isComponentNotification) {
                    setDefaultIcon(icon);
                } else {
                    setDefaultIcon(faSpinner);
                }
            }
        },
        [kind, icon, spin, isComponentNotification]
    );

    const rightGridColumns =
        ((size === "small" || size === "big") &&
            close &&
            ({
                "--right-grid-template-column": "1fr 1fr",
                "--right-padding-right-big": "0px",
                "--right-padding-right-small": "0px",
            } as CSSProperties)) ||
        undefined;

    return (
        <div
            className={[className, "notification"].join(" ").trim()}
            data-kind={kind}
            data-size={size}
            style={rightGridColumns}
        >
            <div className="left">
                <div className="iconandmessage">
                    {isSnackbar ? null : (
                        <>
                            {(shouldHideIcon === false && defaultIcon && (
                                <div className="notificationiconwrapper">
                                    <FontAwesomeIcon
                                        icon={defaultIcon}
                                        spin={shouldSpin && !isComponentNotification}
                                        fixedWidth={true}
                                    />
                                </div>
                            )) ||
                                null}
                        </>
                    )}
                    <span className="labelText" data-kind={kind} data-size={size}>
                        {label}
                    </span>
                </div>
            </div>

            <div className="right">
                {isComponentNotification ? (
                    <button className="actionbutton" onClick={onActionClick} data-size={size} data-kind={kind}>
                        <FontAwesomeIcon icon={faChevronRight} className="ComponentNotification" />
                    </button>
                ) : hasActionLabel ? (
                    <button className="actionbutton" onClick={onActionClick} data-size={size} data-kind={kind}>
                        {actionLabel}
                    </button>
                ) : null}
                {hasCloseButton && (size === "small" || size === "big") ? (
                    <button className="close" onClick={onClose}>
                        <FontAwesomeIcon icon={faXmark} className="ComponentNotification" />
                    </button>
                ) : null}
            </div>
        </div>
    );
};

export default Notification;
