import React, { createContext, useMemo, useState } from 'react';

type NotatContext = {
    notat: string | undefined;
    setNotat: React.Dispatch<React.SetStateAction<string | undefined>>;
    serverNotat: string | undefined;
    setServerNotat: React.Dispatch<React.SetStateAction<string | undefined>>;
};

export const NotatContext = createContext<NotatContext>({} as NotatContext);

export const NotatProvider = (props: any) => {
    const [notat, setNotat] = useState<string>();
    const [serverNotat, setServerNotat] = useState<string>();

    const value = useMemo(
        () => ({
            notat,
            setNotat,
            serverNotat,
            setServerNotat,
        }),
        [notat, serverNotat]
    );

    return <NotatContext.Provider value={value}>{props.children}</NotatContext.Provider>;
};
