import { Api } from '@tidsbanken/data';
import { apiSjekkliste, Domain } from '../components/CheckList/api';

// api-hjem
export const apiURL = Api.url({ repo: 'hjem' });

// api-ansatte
export const apiURLAnsatte = Api.url({ repo: 'ansatte' });

const vacationUrl = apiURL + 'feriedager/';
const shiftUrl = apiURL + 'vakter/';
const todaysShiftUrl = apiURL + 'dagensvakter';
const projectInfoUrl = apiURL + 'prosjektinfo/';
const stempleStatusUrl = apiURL + 'stemplestatus/';
const motdUrl = apiURL + 'motd';
const unreadDocumentsUrl = apiURL + 'UlesteDokumenter';
const availableShiftsUrl = apiURL + 'ledigevakter';
const profileDetailsUrl = apiURL + 'ansatt';
const menuListUrl = apiURL + 'menypunkter/';
const feedbackUrl = apiURL + 'optUtFeedback';
const hourlyBudgetUrl = apiURL + 'timebudsjett';
const anniversaries = apiURL + 'jubileer/';

// api-sjekkliste
const checkListUrl = Api.url({ repo: 'sjekkliste' });

// api-stemple
const apiStemple =
    window.location.hostname === 'lokal.tidsbanken.net'
        ? 'https://sandkasse.tidsbanken.net/api/stemple/v2'
        : '/api/stemple/v2';

const tilPause = apiStemple + '/TilPause';
const fraPause = apiStemple + '/FraPause';

// api-me
export const apiMeUrl = Api.url({ repo: 'me' });

// api-settings
const settingUrl = Api.url({
    repo: 'settings',
    path: 'customer/home',
});

export const projectSettingsUrl = Api.url({
    repo: 'settings',
    path: 'modules',
});

// api-settings
const stampInSettingUrl = Api.url({
    repo: 'settings',
    path: 'customer/stampIn',
});

// API-Omsetning
export const omsetningsUrl =
    window.location.href.includes('lokal.tidsbanken') || window.location.href.includes('sandkasse.tidsbanken')
        ? 'https://api-omsetning-sandkasse.tidsbanken.net'
        : 'https://api-omsetning.tidsbanken.net';

// API-Planlegging
export const planleggingUrl =
    window.location.href.includes('lokal.tidsbanken') || window.location.href.includes('sandkasse.tidsbanken')
        ? 'https://sandkasse.tidsbanken.net/api/planlegging'
        : 'https://min.tidsbanken.net/api/planlegging';

export const timelinjeUrl =
    window.location.href.includes('lokal.tidsbanken') || window.location.href.includes('sandkasse.tidsbanken')
        ? 'https://sandkasse.tidsbanken.net/api/timelinje'
        : 'https://min.tidsbanken.net/api/timelinje';

// API-Rapport
const timeAccUrl =
    window.location.href.includes('lokal.tidsbanken') || window.location.href.includes('sandkasse.tidsbanken')
        ? 'https://sandkasse.tidsbanken.net/api/timelinje/Timesaldo'
        : 'https://min.tidsbanken.net/api/timelinje/Timesaldo';

// Memoizer
export const memoize = <F extends (...args: any[]) => any>(func: F) => {
    const cache: { [key: string]: ReturnType<F> | undefined } = {};

    return (...args: Parameters<F>) => {
        const key = JSON.stringify(args);
        const cached = cache[key];

        if (cached !== undefined) {
            return cached;
        }

        return (cache[key] = func(...args));
    };
};

// Generic fetchers

export const singleItemFetch = (url: string) => {
    return fetch(`${url}`, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        //mode: "no-cors", // no-cors, *cors, same-origin
        credentials: 'include', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
        },
    }).then((response) => {
        if (response.status === 401 || response.status === 403) {
            throw new Error('Unauthorized');
        }
        if (response.status === 204) {
            return response;
        } else {
            return response.json();
        }
    });
};

const singleItemPost = (url: string) => {
    return fetch(`${url}`, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        //mode: "no-cors", // no-cors, *cors, same-origin
        credentials: 'include', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
        },
    }).then((response) => {
        if (response.ok) {
            return response.json();
        }

        throw new Error('Feilet på å laste data');
    });
};

const itemPost = (url: string, data: string) => {
    return fetch(`${url}`, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        //mode: "no-cors", // no-cors, *cors, same-origin
        credentials: 'include', // include, *same-origin, omit
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: data,
    }).then((response) => response.json());
};

const singleItemPostCheckList = (url: string) => {
    return fetch(`${url}`, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        //mode: "no-cors", // no-cors, *cors, same-origin
        credentials: 'include', // include, *same-origin, omit
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    }).then((response) => response);
};

//-------------------
// Sjekklistemal post metode
//-------------------

export const postCheckList = (id: string) => {
    const checkListPostUrl = checkListUrl + `Klasse(${id})/CreateInstance`;
    return singleItemPostCheckList(checkListPostUrl);
};

//----------------
// Sjekklistemal get metode
//----------------

export const getCheckListTemplates = memoize(() => {
    return apiSjekkliste.get<Domain.Klasse[]>(`Hjem/MineKlasser`);
});

// Post metode for tilbakemeldingsvindu

export const postFeedback = (data: string) => {
    return itemPost(feedbackUrl, data);
};

// Post methods for pause button, they must NOT use memoize

export const postTilPause = () => {
    return singleItemPost(tilPause);
};

export const postFraPause = () => {
    return singleItemPost(fraPause);
};

//----------------
// Vacation days fetching
//----------------

export const getVacationInfo = memoize((hashedRes: string) => {
    return singleItemFetch(`${vacationUrl}?_=${hashedRes}`);
});

export const getTimeAccountInfo = memoize(() => {
    return singleItemFetch(timeAccUrl);
});

//----------------
// Shift information fetching
//----------------

export const getShiftsForWeek = memoize((weekDate: string) => {
    return singleItemFetch(shiftUrl + weekDate);
});

// Fetcher for getting next shift

export const getTodaysShift = memoize(() => {
    return singleItemFetch(todaysShiftUrl);
});
// Project info fetcher

export const getProjectInfo = memoize(() => {
    return singleItemFetch(projectInfoUrl);
});

// Stemple status fetcher, med info om første innstemplingsinntidspunkt

export const getStempleStatus = memoize(() => {
    return singleItemFetch(stempleStatusUrl);
});

// Message of the day fetcher

export const getMotd = memoize((hashedRes: string) => {
    return singleItemFetch(`${motdUrl}?_=${hashedRes}`);
});

// Kvalitetsmanual heading(unread documents) fetcher

export const getUnreadDocuments = memoize(() => {
    return singleItemFetch(`${unreadDocumentsUrl}`);
});

// Available shifts for vakttorg panel

export const getAvailableShifts = memoize(() => {
    return singleItemFetch(availableShiftsUrl);
});


export const getProfileDetails = () => {
    return singleItemFetch(profileDetailsUrl);
};

// Api-me fetcher

export const getApiMe = memoize(() => {
    return singleItemFetch(apiMeUrl);
});

export const getListOfMenus = memoize((hashedRes: string) => {
    return singleItemFetch(`${menuListUrl}?_=${hashedRes}`);
});

// Component settings fetcher

export const getComponentsToRender = memoize(() => {
    return singleItemFetch(settingUrl);
});

export const getStampInSettings = memoize(() => {
    return singleItemFetch(stampInSettingUrl);
});

// Anniversary fetcher
export const getAnniversaries = memoize((date: string, hashedRes: string) => {
    return singleItemFetch(`${anniversaries + date}?_=${hashedRes}`);
});


export const getUsedTime = memoize(() => {
    return singleItemFetch(hourlyBudgetUrl);
});

export const fetchWithTimeout = memoize((resource: string) => {
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), 3000);
    return fetch(resource, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        signal: controller.signal,
    }).then((response) => {
        clearTimeout(id);
        if (response.status === 204) {
            return response;
        } else {
            return response.json();
        }
    });
});

// opat
export const getSumAvdelingerOpat = memoize(
    (departmentId: string, ipType = false, startDate?: string, endDate?: string) => {
        if (startDate && endDate) {
            return singleItemFetch(
                `${omsetningsUrl}/opat/SumAvdelinger/${departmentId}?startDate=${startDate}&endDate=${endDate}`
            );
        }
        if (ipType) {
            `${omsetningsUrl}/opat/SumAvdelinger/${departmentId}?ipType=I&last=true`;
        }
        return singleItemFetch(`${omsetningsUrl}/opat/SumAvdelinger/${departmentId}`);
    }
);

export const getOpatSumDepartmentTurnover = memoize(
    (departmentId: string, last: boolean, startDate?: string, endDate?: string) => {
        if (last) {
            return singleItemFetch(`${omsetningsUrl}/opat/SumAvdelinger/${departmentId}?ipType=I&last=true`);
        }
        if (startDate && endDate) {
            return singleItemFetch(
                `${omsetningsUrl}/opat/SumAvdelinger/${departmentId}?startDate=${startDate}&endDate=${endDate}`
            );
        }
        return singleItemFetch(`${omsetningsUrl}/opat/SumAvdelinger/${departmentId}`);
    }
);
