import * as React from 'react';
import './Spinner.less';

type Props = {
    width: string | number | undefined;
}

const Spinner = ({width}: Props) => {

    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" className="SpinnerClass" fill="#29343d" width={width} viewBox="0 0 88 88">
                <defs>
                </defs>
                <title>Tidsbanken_Spinner</title>

                <g id="Layer_1" data-name="Layer 1">
                    <path className="cls-1" d="M70.2,8.66a42.16,42.16,0,0,0-8.7-5L58,19.75Z" />
                    <path className="cls-1" d="M84.36,26.5a42.16,42.16,0,0,0-5-8.7L68.25,30Z" />
                    <path className="cls-1" d="M87.71,49a43.59,43.59,0,0,0,0-10L72,44Z" />
                    <path className="cls-1" d="M79.34,70.2a42.16,42.16,0,0,0,5-8.7L68.25,58Z" />
                    <path className="cls-1" d="M61.5,84.36a42.16,42.16,0,0,0,8.7-5L58,68.25Z" />
                    <path className="cls-1" d="M39,87.71a43.59,43.59,0,0,0,10,0L44,72Z" />
                    <path className="cls-1" d="M17.8,79.34a42.16,42.16,0,0,0,8.7,5L30,68.25Z" />
                    <path className="cls-1" d="M3.64,61.5a42.16,42.16,0,0,0,5,8.7L19.75,58Z" />
                    <path className="cls-1" d="M.29,39a43.59,43.59,0,0,0,0,10L16,44Z" />
                    <path className="cls-1" d="M8.66,17.8a42.16,42.16,0,0,0-5,8.7L19.75,30Z" />
                    <path className="cls-1" d="M26.5,3.64a42.16,42.16,0,0,0-8.7,5L30,19.75Z" />
                    <path className="cls-1" d="M49,.29a43.59,43.59,0,0,0-10,0L44,16Z" />
                </g>
            </svg>
        </>
    )
};

export default Spinner;
