import { useEffect, useState } from 'react';
import { usePhrases } from '@tidsbanken/phrases';
import './TimeCounter.less';

type Props = {
    msTime: number;
    timeProcessed: number;
};

const TimeCounter = ({ msTime, timeProcessed }: Props) => {
    const dt = usePhrases('datetime');
    const [startTime, setStartTime] = useState<number>(msTime + timeProcessed > 0 ? msTime + timeProcessed : 0);
    let initTime: number;

    // Convert milliseconds to format HH:MM:SS
    const toHHMMSS = (ms: number) => {
        const min = Math.floor((ms / 60 / 1000) % 60);
        const hour = Math.floor(ms / 3600 / 1000);

        const hours = hour < 10 ? '0' + hour : hour;
        const minutes = min < 10 ? '0' + min : min;

        return `${hours}${dt('hour').substring(0, 1)} ${minutes}${dt('minute').substring(0, 1)}`;
    };
    useEffect(() => {
        initTime = new Date().getTime();
        const interval = setInterval(() => {
            const currentTime = new Date().getTime();
            const diff = currentTime - initTime;
            setStartTime((startTime) => startTime + diff);
        }, 1000);
        return () => clearInterval(interval);
    });

    return <span className="TimeCounter">{msTime === 0 ? toHHMMSS(timeProcessed) : toHHMMSS(startTime)}</span>;
};

export default TimeCounter;
