import { useEffect, useState } from 'react';

const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(
        /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    );

    useEffect(() => {
        const checkIsMobile = () => {
            setIsMobile(/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
        };
        checkIsMobile();
        const debouncedCheckIsMobile = debounce(checkIsMobile, 15);

        window.addEventListener('resize', debouncedCheckIsMobile);

        return () => {
            window.removeEventListener('resize', debouncedCheckIsMobile);
        };
    }, []);

    return isMobile;
};

export default useIsMobile;

// Utility function for debouncing, with TypeScript annotations
function debounce(func: (...args: unknown[]) => void, wait: number, immediate?: boolean): (...args: unknown[]) => void {
    let timeout: NodeJS.Timeout | null = null;
    return (...args: unknown[]) => {
        const later = () => {
            timeout = null;
            if (!immediate) func(...args);
        };
        const callNow = immediate && !timeout;
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func(...args);
    };
}
