import useSWR from 'swr';
import { projectSettingsUrl, singleItemFetch } from '../helpers/dataFetcher';
import { CustomerModules } from '../helpers/dataTypeHelper';

export function useModules() {
    const { data, error, mutate } = useSWR<CustomerModules>(projectSettingsUrl, singleItemFetch, {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
    });

    return {
        modules: data ?? {
            Prosjekt: false,
            Avvik: false,
            Kvalitetsmanual: false,
            Sjekklister: false,
            Vakttorg: false,
        },
        isLoading: !error && !data,
        isError: error,
        mutate: mutate,
    };
}
