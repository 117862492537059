import * as React from 'react';
import { faArrowAltCircleLeft } from '@fortawesome/pro-solid-svg-icons/faArrowAltCircleLeft';
import { faArrowAltCircleRight } from '@fortawesome/pro-solid-svg-icons/faArrowAltCircleRight';
import { usePhrases } from '@tidsbanken/phrases';
import { GenericCustomButton, MeetingVelferdButtons, StempleStatus } from '../helpers/dataTypeHelper';
import StampButton from './ui/StampButton';

enum ButtonTypes {
    StampIn = 'StampIn',
    StampOut = 'StampOut',
    StampToPause = 'StampToPause',
    StampToPauseDisabled = 'StampToPauseDisabled',
    Custom = 'CustomStamp',
    Change = 'ChangeStamp',
}

type Props = {
    stampedIn: boolean;
    stampStatus: StempleStatus;
    buttons: MeetingVelferdButtons;
};

const CustomStampButtonsContainer = ({ stampedIn, stampStatus, buttons }: Props) => {
    const p = usePhrases('hjem');

    const meetingButtons = buttons.MeetingButtons.filter((button) => button != null);
    const velferdButtons = buttons.VelferdButtons.filter((button) => button != null);

    let meetingButton;
    let velferdButton;

    const customButtonText1: string = p('stamp_meet');
    const customButtonText2: string = p('stamp_welfare');

    let activityCheck = false;

    //Helper function for meeting/velferd logic when clocked in
    const helperClockedIn = (
        buttons: GenericCustomButton[],
        buttonName: string,
        buttonName2: string,
        customButtonText: string,
        customButtonId: number
    ) => {
        const activityStampedOn = stampStatus.ActivityStampedOn.ArbeidsType?.Id;
        const innTilButton = buttons.filter((button) => button.Navn == buttonName);
        if (innTilButton.length > 0) {
            activityCheck = innTilButton[0].Verdi == activityStampedOn;
        }
        //Logic for when the button has been pressed and the user is stamped out to meeting/velferd
        if (activityCheck) {
            //check if button array contains InnTilButton
            const oppositeNameCheck = buttonName.replace('UtTil', 'InnFra');
            const inFromArray = buttons.filter((button) => button.Navn === oppositeNameCheck);
            const customButtonObject = inFromArray[0];

            if (customButtonObject !== undefined) {
                if (customButtonObject !== null) {
                    if (customButtonObject.CustomText !== null) {
                        customButtonText = customButtonObject.CustomText.Verdi;
                    }
                }

                return (
                    <StampButton
                        faIcon={faArrowAltCircleLeft}
                        type={ButtonTypes.Custom}
                        text={`${p('stamp_from')} ${customButtonText}`}
                        link={'/stemple4.asp?handling=bytt'}
                    />
                );
            } else {
                return '';
            }
        } else {
            //Logic for when the user has not interacted with the button or has stamped in from the meeting/velferd
            const customButtonObject = buttons.find((el) => el.Navn == buttonName);
            const customButtonObject2 = buttons.find((el) => el.Navn == buttonName2);
            const customButton2Value = customButtonObject2 ? customButtonObject2.Verdi : '';

            if (customButtonObject !== undefined) {
                if (customButtonObject !== null) {
                    if (customButtonObject.CustomText !== null) {
                        customButtonText = customButtonObject.CustomText.Verdi;
                    }
                }

                return (
                    <StampButton
                        faIcon={faArrowAltCircleRight}
                        type={ButtonTypes.Custom}
                        text={`${p('stamp_to')} ${customButtonText}`}
                        link={`stemple4.asp?handling=bytt&iarb=${customButtonObject.Verdi}${customButton2Value}&tilpassetStempleknappId=${customButtonId}`}
                    />
                );
            } else {
                return '';
            }
        }
    };

    //Helper function for meeting/velferd logic when clocked out
    const helperClockedOut = (
        buttons: GenericCustomButton[],
        buttonName: string,
        buttonName2: string,
        customButtonText: string
    ) => {
        const customButtonObject = buttons.find((el) => el.Navn == buttonName);
        const customButtonObject2 = buttons.find((el) => el.Navn == buttonName2);
        const customButton2Value = customButtonObject2 ? customButtonObject2.Verdi : '';

        if (customButtonObject !== undefined) {
            if (customButtonObject !== null) {
                if (customButtonObject.CustomText !== null) {
                    customButtonText = customButtonObject.CustomText.Verdi;
                }
            }

            return (
                <StampButton
                    faIcon={faArrowAltCircleLeft}
                    type={ButtonTypes.Custom}
                    text={`${p('stamp_from_2')} ${customButtonText}`}
                    link={`stemple-oppgitidspunkt.asp?h=inn&iarb=${customButtonObject.Verdi}${customButton2Value}&tilbaketil=stemple4.asp%3Fhandling=bytt`}
                />
            );
        } else {
            return '';
        }
    };

    if (velferdButtons.length > 0) {
        if (stampedIn) {
            velferdButton = helperClockedIn(
                velferdButtons,
                'StempleUtTilVelferd',
                'StempleUtTilVelferd2',
                customButtonText2,
                2
            );
        } else {
            velferdButton = helperClockedOut(
                velferdButtons,
                'StempleInnFraVelferd',
                'StempleInnFraVelferd2',
                customButtonText2
            );
        }
    }

    if (meetingButtons.length > 0) {
        if (stampedIn) {
            meetingButton = helperClockedIn(
                meetingButtons,
                'StempleUtTilMoete',
                'StempleUtTilMoete2',
                customButtonText1,
                1
            );
        } else {
            meetingButton = helperClockedOut(
                meetingButtons,
                'StempleInnFraMoete',
                'StempleInnFraMoete2',
                customButtonText1
            );
        }
    }

    return (
        <React.Fragment>
            {meetingButton}
            {velferdButton}
        </React.Fragment>
    );
};

export default CustomStampButtonsContainer;
