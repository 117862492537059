import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { faPauseCircle } from '@fortawesome/pro-solid-svg-icons/faPauseCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@tidsbanken/components/buttons/Button';
import { usePhrases } from '@tidsbanken/phrases';
import { getStempleStatus } from '../helpers/dataFetcher';
import { StempleStatus } from '../helpers/dataTypeHelper';
import './PausePage.less';

type Props = {
    countdown: string;
    fromToText: string;
    handleContinueClick: () => void;
};

const PausePage = ({ countdown, fromToText, handleContinueClick }: Props) => {
    // Sets the countdown number, if the timeout is not set in settings, the default is 10 seconds
    const countdownNumber = countdown !== '' ? parseInt(countdown) : 10;
    const [counter, setCounter] = useState<number>(countdownNumber);
    const [logoutOnDone, setLogoutOnDone] = useState<boolean>(true);
    const p = usePhrases('hjem');

    const fromToDescriptor = fromToText === 'fra' ? 'pause_from' : 'pause_to';

    const fromToSuccessDescriptor = fromToText === 'fra' ? 'pause_from_success' : 'pause_to_success';

    const handleLogOutClick = () => {
        if (!logoutOnDone) {
            handleContinueClick();
            return;
        }

        const authUrl = () => {
            if (window.location.hostname === 'min.tidsbanken.net') {
                return 'https://auth.tidsbanken.net/';
            } else {
                return 'https://auth-sandkasse.tidsbanken.net/';
            }
        };

        fetch(authUrl() + 'api/SignOut/Bruker', {
            mode: 'no-cors',
            credentials: 'include',
        }).then(() => {
            window.location.href = authUrl();
        });
    };

    useEffect(() => {
        getStempleStatus().then((data: StempleStatus) => {
            setLogoutOnDone(data.logoutOnDone);
        });
    }, []);

    useEffect(() => {
        const timeout = setTimeout(() => setCounter(counter - 1), 1000);
        if (counter === 0) {
            handleLogOutClick();
        }

        return () => clearTimeout(timeout);
    }, [counter]);

    return (
        <div className="PageWrapper">
            <div className="PausePage">
                <h3>{p(fromToDescriptor)}</h3>
                <div className="PauseMessage">
                    <div className="PauseMessageBody">
                        <FontAwesomeIcon className="ExclamationTriangle" icon={faPauseCircle} />
                        <p className="MessageWrapper">{p(fromToSuccessDescriptor, `${counter}`)}</p>
                    </div>
                </div>
                <div className="ButtonWrapper">
                    {logoutOnDone && (
                        <Button
                            className="Button DoneButton"
                            kind="info"
                            label={p('pause_done')}
                            onClick={handleLogOutClick}
                        />
                    )}
                    <Link to="/hjem"></Link>
                    <Button
                        className="Button ContinueButton"
                        label={p('pause_continue')}
                        onClick={handleContinueClick}
                    />
                </div>
            </div>
        </div>
    );
};

export default PausePage;
