import React from 'react';
import { FormEvent, useState } from 'react';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@tidsbanken/components/buttons/Button';
import Input from '@tidsbanken/components/Input';
import { usePhrases } from '@tidsbanken/phrases';
import { addMonths, getDate, getDaysInMonth, getYear, isValid, parseISO, startOfYear } from 'date-fns';
import format from 'date-fns/format';
import { apiURLAnsatte } from '../../helpers/dataFetcher';
import { Ansatt } from '../../helpers/dataTypeHelper';
import './ProfileDetailsEdit.less';

type Props = {
    employeeData: Ansatt;
    handleDetailsSave: (data: Ansatt) => void;
    handleClose: () => void;
};

const ProfileDetailsEdit: React.FC<Props> = (props: Props) => {
    const [mobile, setMobile] = useState<string>(props.employeeData.Mobil || '');
    const [email, setEmail] = useState<string>(props.employeeData.Epost || '');
    const [address, setAddress] = useState<string>(props.employeeData.Adresse || '');
    const [postalCode, setPostalCode] = useState<string>(props.employeeData.Postnummer || '');
    const [postCity, setPostCity] = useState<string>(props.employeeData.Poststed || '');
    const [iceName, setIceName] = useState<string>(props.employeeData.IceNavn || '');
    const [iceNumber, setIceNumber] = useState<string>(props.employeeData.IceNr || '');
    const [socialNumber, setSocialNumber] = useState<string>(props.employeeData.Personnummer || '');
    const [wageAccount, setWageAccount] = useState<string>(props.employeeData.Lonnskonto || '');
    const employeeDataFodt = props.employeeData.Fodt;

    const [birthDay, setBirthDay] = useState<number>(employeeDataFodt ? getDate(parseISO(employeeDataFodt)) : 0);
    const [birthMonth, setBirthMonth] = useState<string>(
        employeeDataFodt ? format(parseISO(employeeDataFodt), 'MMMM') : ''
    );
    const [birthYear, setBirthYear] = useState<number>(employeeDataFodt ? getYear(parseISO(employeeDataFodt)) : 0);

    const p = usePhrases('hjem');
    const months = findMonths();

    const handlerSaveEdit = () => {
        const parsed = new Date(birthYear, months.indexOf(birthMonth));
        const bornFormatted = format(new Date(birthYear, parsed.getMonth(), birthDay), 'yyyy-MM-dd');
        const bornOrNull = isValid(new Date(bornFormatted)) ? bornFormatted : null;

        const updatedDetails = {
            Mobil: mobile,
            Epost: email,
            Adresse: address,
            Postnummer: postalCode,
            Poststed: postCity,
            IceNavn: iceName,
            IceNr: iceNumber,
            Fodt: bornOrNull,
            Personnummer: socialNumber,
            Lonnskonto: wageAccount,
        };

        // TODO: bytt til apiURLAnsatte og finn ut hvorfor det ikke funker
        fetch(apiURLAnsatte + 'ansatt', {
            method: 'PATCH',
            credentials: 'include',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedDetails),
        })
            .then((res) => res.json())
            .then((data) => {
                props.handleDetailsSave(data);
            })
            .catch(() => {
                console.error('Klarte ikke å lagre endringene!');
            });
    };

    const getNumberOfDaysArray = (): number[] => {
        const days = getNumberOfDays();

        const dayList: number[] = [];

        for (let i = 1; i <= days; i++) {
            dayList.push(i);
        }

        return dayList;
    };

    const getNumberOfDays = (): number => {
        let days = 31;

        days = getDaysInMonth(new Date(birthYear, months.indexOf(birthMonth)));

        return days;
    };

    const getNumberOfYearsArray = (): number[] => {
        const years: number[] = [];

        const currentYear = getYear(new Date());

        for (let i = 0; i < 106; i++) {
            years.push(currentYear - i);
        }

        return years;
    };

    return (
        <div className="DetailContainer">
            {props.employeeData ? (
                <div>
                    <div className="DetailInfo">
                        <div className="DetailInfo">
                            <div className="DetailInfoLine">
                                <label htmlFor="fornavn">
                                    {p('pr_dtl_phone')}:
                                    <Input
                                        maxLength={30}
                                        onChange={(e: FormEvent<HTMLInputElement>) => {
                                            setMobile(e.currentTarget.value);
                                        }}
                                        id="fornavn"
                                        value={mobile}
                                    />
                                </label>
                            </div>
                            <div className="DetailInfoLine">
                                <label htmlFor="etternavn">
                                    {p('pr_dtl_email')}:
                                    <Input
                                        maxLength={50}
                                        onChange={(e: FormEvent<HTMLInputElement>) => {
                                            setEmail(e.currentTarget.value);
                                        }}
                                        id="etternavn"
                                        value={email}
                                    />
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="DetailInfo">
                        <div className="DetailInfo">
                            <div className="DetailInfoLineEdit">
                                <label htmlFor="adresse">
                                    {p('pr_dtl_addr')}:
                                    <Input
                                        maxLength={50}
                                        onChange={(e: FormEvent<HTMLInputElement>) => {
                                            setAddress(e.currentTarget.value);
                                        }}
                                        id="adresse"
                                        value={address}
                                    />
                                </label>
                                <label htmlFor="postnr">
                                    {p('pr_dtl_zip')}:
                                    <Input
                                        maxLength={20}
                                        onChange={(e: FormEvent<HTMLInputElement>) => {
                                            setPostalCode(e.currentTarget.value);
                                        }}
                                        id="postnr"
                                        value={postalCode}
                                    />
                                </label>
                                <label htmlFor="poststed">
                                    {p('pr_dtl_loc')}:
                                    <Input
                                        maxLength={30}
                                        onChange={(e: FormEvent<HTMLInputElement>) => {
                                            setPostCity(e.currentTarget.value);
                                        }}
                                        id="poststed"
                                        value={postCity}
                                    />
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="DetailInfo">
                        <div className="DetailInfo">
                            <div className="DetailInfoLine Birthday">
                                <span className="DetailLabel">{p('pr_dtl_birth')}:</span>
                                <div className="BirthdayContainer">
                                    <div className="BirthdayDropdownWrapper">
                                        <select
                                            value={birthDay}
                                            onChange={(e: FormEvent<HTMLSelectElement>) =>
                                                setBirthDay(parseInt(e.currentTarget.value, 10))
                                            }
                                        >
                                            <option disabled={true} value={0}>
                                                {p('pr_dtl_birth_d')}:
                                            </option>
                                            {getNumberOfDaysArray().map((day: number) => (
                                                <option key={'day' + day} value={day}>
                                                    {day}
                                                </option>
                                            ))}
                                        </select>
                                        <FontAwesomeIcon className="BirthdayExpandDropdownIcon" icon={faCaretDown} />
                                    </div>

                                    <div className="BirthdayDropdownWrapper">
                                        <select
                                            value={birthMonth}
                                            onChange={(e: FormEvent<HTMLSelectElement>) =>
                                                setBirthMonth(e.currentTarget.value)
                                            }
                                        >
                                            <option disabled={true} value={''}>
                                                {p('pr_dtl_birth_m')}:
                                            </option>
                                            {months.map((month: string) => (
                                                <option key={month} value={month}>
                                                    {month}
                                                </option>
                                            ))}
                                        </select>
                                        <FontAwesomeIcon className="BirthdayExpandDropdownIcon" icon={faCaretDown} />
                                    </div>

                                    <div className="BirthdayDropdownWrapper">
                                        <select
                                            value={birthYear}
                                            onChange={(e: FormEvent<HTMLSelectElement>) =>
                                                setBirthYear(parseInt(e.currentTarget.value, 10))
                                            }
                                        >
                                            <option disabled={true} value={0}>
                                                {p('pr_dtl_birth_y')}:
                                            </option>
                                            {getNumberOfYearsArray().map((year: number) => (
                                                <option key={'year' + year} value={year}>
                                                    {year}
                                                </option>
                                            ))}
                                        </select>
                                        <FontAwesomeIcon className="BirthdayExpandDropdownIcon" icon={faCaretDown} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="DetailInfo">
                        <div className="DetailInfo">
                            <div className="DetailInfoLine">
                                <label htmlFor="personnummer">
                                    {p('pr_dtl_pers_nr')}:
                                    <Input
                                        maxLength={30}
                                        onChange={(e: FormEvent<HTMLInputElement>) => {
                                            setSocialNumber(e.currentTarget.value);
                                        }}
                                        id="personnummer"
                                        value={socialNumber}
                                    />
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="DetailInfo">
                        <div className="DetailInfo">
                            <div className="DetailInfoLine">
                                <label htmlFor="lonnskonto">
                                    {p('pr_dtl_pay')}:
                                    <Input
                                        maxLength={30}
                                        onChange={(e: FormEvent<HTMLInputElement>) => {
                                            setWageAccount(e.currentTarget.value);
                                        }}
                                        id="lonnskonto"
                                        value={wageAccount}
                                    />
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="DetailInfo">
                        <div className="DetailInfo">
                            <div className="DetailInfoLine">
                                <label htmlFor="iceNavn">
                                    {p('pr_dtl_close_rel_name')}:
                                    <Input
                                        maxLength={50}
                                        onChange={(e: FormEvent<HTMLInputElement>) => {
                                            setIceName(e.currentTarget.value);
                                        }}
                                        id="iceNavn"
                                        value={iceName}
                                    />
                                </label>
                            </div>
                            <div className="DetailInfoLine">
                                <label htmlFor="iceNr">
                                    {p('pr_dtl_close_rel_phone')}:
                                    <Input
                                        maxLength={30}
                                        onChange={(e: FormEvent<HTMLInputElement>) => {
                                            setIceNumber(e.currentTarget.value);
                                        }}
                                        id="iceNr"
                                        value={iceNumber}
                                    />
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="DetailButtons">
                        <Button label={p('pr_dtl_btn_close')} kind="error" flat={true} onClick={props.handleClose} />
                        <Button onClick={handlerSaveEdit} label={p('pr_dtl_btn_save')} kind="info" />
                    </div>
                </div>
            ) : (
                <div>{p('pr_dtl_loading')}</div>
            )}
        </div>
    );
};

function findMonths(): string[] {
    const months: string[] = [];

    const currentMonth = new Date();
    const firstMonth = startOfYear(currentMonth);

    for (let i = 0; i < 12; i++) {
        const month = addMonths(firstMonth, i);
        months.push(format(month, 'MMMM'));
    }

    return months;
}

export default ProfileDetailsEdit;
