import * as React from 'react';
import Button from '@tidsbanken/components/buttons/Button';
import {useState} from 'react';
import ProfileCardEdit from './ProfileCardEdit';
import {Stemplekort} from '../../helpers/dataTypeHelper';
import {usePhrases} from '@tidsbanken/phrases';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from '@fortawesome/pro-regular-svg-icons/faTrash';
import {faIdCard} from "@fortawesome/pro-regular-svg-icons/faIdCard";

type Props = {
    card: Stemplekort;
    deleteHandler?: (value: any) => void;
    handlerEditSave: Function;
};

export function ProfileCard(props: Props) {
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [card, setCard] = useState<Stemplekort>(props.card);

    const p = usePhrases("hjem");

    return (
        isEditing ? (
            <div>
                <ProfileCardEdit
                    handlerEditSave={(card: Stemplekort) => {
                        props.handlerEditSave(card);
                        setCard(card);
                        setIsEditing(false);
                    }}
                    card={card}
                />
            </div>
        ) : (
            <div className="CardContainer">
                <div className="CardAlign">
                    <div className="CardAlignLeft">
                        <div className={"HeaderProfileCard"}>
                            <FontAwesomeIcon className={"HeaderProfileCardIcon"} icon={faIdCard} />
                            <p className={"HeaderProfileCardText"}>{ card ? card.Kode : ''}</p>
                        </div>
                    </div>
                    <div className="CardAlignRight">
                        {props.card.Id ?
                            <Button
                                onClick={_ => (setIsEditing(true))}
                                label={p("pr_btn_edit")}
                                kind='info'
                                flat={true}
                            />
                            : ''
                        }
                        <Button
                            onClick={props.deleteHandler}
                            icon={faTrash}
                            iconButton={true}
                            kind='error'
                            flat={true}
                        />
                    </div>
                </div>
            </div>
        )
    );
}
