import React from 'react';
import { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';
import { Box, Theme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Ansattkalender } from '@tidsbanken/ansattkalender';
import { usePhrases } from '@tidsbanken/phrases';
import {
    apiMeUrl,
    fetchWithTimeout,
    getCheckListTemplates,
    getComponentsToRender,
    getListOfMenus,
    getUnreadDocuments,
    postFraPause,
    postTilPause,
} from '../helpers/dataFetcher';
import { ApiMe, ComponentSettings, MenuData, MenyPunkt } from '../helpers/dataTypeHelper';
import { hashQueryString } from '../helpers/hashHelper';
import { useModules } from '../hooks/use-modules';
import useIsMobile from '../hooks/useIsMobile';
import { Domain } from './CheckList/api';
import CheckListContainer from './CheckList/CheckListContainer';
import BirthdaysAndAnniversariesError from './ErrorComponents/BirthdaysAndAnniversariesError';
import PulsPanelError from './ErrorComponents/PulsPanelError';
import TodayError from './ErrorComponents/TodayError';
import VacationPanelError from './ErrorComponents/VacationPanelError';
import { HeadingManual, HeadingManualLoader } from './ui/Heading';
import NewFeaturePanel from './ui/NewFeaturePanel';
import RegisterDeviationButton from './ui/RegisterDeviationButton';
import BirthdaysAndAnniversaries from './BirthdaysAndAnniversaries';
import MenuList from './MenuList';
import PausePage from './PausePage';
import PulsPanel from './PulsPanel';
import ShiftHeading from './ShiftHeading';
import Toast from './Toast';
import Today from './Today';
import { Panel } from './ui';
import VacationPanel from './VacationPanel';
import './Home.less';

type Props = {
    handleNotLoggedIn: (loggedIn: boolean) => void;
    randomNum: number;
};

const Home = ({ handleNotLoggedIn, randomNum }: Props) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [unreadDocuments, setUnreadDocuments] = useState<number>(0);
    const [manualItem, setManualItem] = useState<MenyPunkt>();
    const [deviationItem, setDeviationItem] = useState<MenyPunkt>();
    const [filteredMenuList, setFilteredMenuList] = useState<MenyPunkt[]>([]);
    const [componentSettings, setCompSettings] = useState<ComponentSettings>({
        Calendar: false,
        Checklists: false,
        Shifts: false,
        Today: false,
        VacationDaysAndTimeBank: false,
        HourlyBudget: false,
        NewShiftSchedule: true,
        Opat: { DisplayOpatForDepartment: [] },
        PlanVacation: false,
        BirthdaysAndAnniversaries: false,
    });
    const [redirectToBreak, setRedirectToBreak] = useState<boolean>(false);
    const [countdown, setCountdown] = useState<string>('');
    const [fromToText, setFromToText] = useState<string>('');
    const [breakInfo, setBreakInfo] = useState<any>();
    const [checkListTemplates, setCheckListTemplates] = useState<Domain.Klasse[]>([]);
    const [apiMe, setApiMe] = useState<ApiMe | undefined>(undefined);
    const [opatDepartmentId, setOpatDepartmentId] = useState<string | undefined>(undefined);
    const { modules } = useModules();

    const p = usePhrases('hjem');
    const navigate = useNavigate();
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('mobile'));
    const isActuallyMobile = useIsMobile();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        setLoading(true);
        getComponentsToRender()
            .then((settings: ComponentSettings) => {
                setCompSettings(settings);
                fetchWithTimeout(apiMeUrl)
                    .then((data: ApiMe) => {
                        setApiMe(data);
                        fetchMoreData(data, settings);
                    })
                    .catch((e: Error) => {
                        console.error(e);
                        fetchMoreData();
                    });
            })
            .catch((e: Error) => {
                if (e.message === 'Unauthorized') {
                    handleNotLoggedIn(false);
                }
                setLoading(false);
            });
    };

    const fetchMoreData = async (apiMe?: ApiMe, settings?: ComponentSettings) => {
        try {
            const hashedRes = hashQueryString(true, randomNum, apiMe);
            const [unReadDocs, listOfMenus]: [number, MenuData] = await Promise.all([
                getUnreadDocuments(),
                getListOfMenus(hashedRes),
            ]);
            setUnreadDocuments(unReadDocs);

            const manual = listOfMenus.MenuList.find((punkt) => punkt.Navn === 'Kvalitetsmanual');
            const deviation = listOfMenus.MenuList.find((punkt) => punkt.Navn === 'Registrere Avvik');

            setManualItem(manual);
            setDeviationItem(deviation);

            const hide = ['Kvalitetsmanual', 'Registrere Avvik'];
            const items = listOfMenus.MenuList.filter((item) => !hide.includes(item.Navn)).sort((a, b) =>
                a.Navn.localeCompare(b.Navn)
            );
            setFilteredMenuList(items);

            if (settings?.Checklists) {
                const checkListTemplates = await getCheckListTemplates();
                setCheckListTemplates(checkListTemplates.data);
            }
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    };

    const handleToBreakClick = () => {
        try {
            setFromToText('til');
            postTilPause()
                .then((data: any) => {
                    setBreakInfo(data);
                    setRedirectToBreak(true);
                })
                .catch(() => {
                    handleNotLoggedIn(false);
                });
        } catch (e) {
            handleNotLoggedIn(false);
        }
    };

    const handleFromBreakClick = () => {
        try {
            setFromToText('fra');
            postFraPause()
                .then((data: any) => {
                    setBreakInfo(data);
                    setRedirectToBreak(true);
                })
                .catch(() => {
                    handleNotLoggedIn(false);
                });
        } catch (e) {
            handleNotLoggedIn(false);
        }
    };

    const setCountdownTime = (value: string) => {
        setCountdown(value);
    };

    const handleContinueClick = () => {
        setRedirectToBreak(false);
    };

    const handleShowCheckListTemplate = () => {
        navigate('/hjem/manuell-sjekkliste');
    };

    const handleOnClockedInDepartmentId = (departmentId: string | undefined) => {
        setOpatDepartmentId(departmentId ? departmentId : apiMe?.Avdeling ?? undefined);
    };

    const displayOpatForDepartmentData = componentSettings?.Opat.DisplayOpatForDepartment.find((d) => {
        if (opatDepartmentId === undefined) {
            return d.DepartmentNumber === apiMe?.Hovedavdeling;
        } else {
            return d.DepartmentNumber === opatDepartmentId;
        }
    });

    return redirectToBreak ? (
        <PausePage countdown={countdown} fromToText={fromToText} handleContinueClick={handleContinueClick} />
    ) : (
        <div className="App">
            <div className="column">
                {componentSettings.Today ? (
                    <ErrorBoundary FallbackComponent={TodayError}>
                        <Today
                            setCountdownTime={setCountdownTime}
                            handleToBreakClick={handleToBreakClick}
                            handleFromBreakClick={handleFromBreakClick}
                            breakInfo={breakInfo}
                            showHourlyBudget={componentSettings.HourlyBudget}
                            onClockedInDepartmentId={handleOnClockedInDepartmentId}
                        />
                    </ErrorBoundary>
                ) : (
                    ''
                )}

                {displayOpatForDepartmentData?.DepartmentNumber ? (
                    <ErrorBoundary FallbackComponent={PulsPanelError}>
                        <PulsPanel
                            apiMe={apiMe}
                            opatSettings={componentSettings}
                            displayOpatForDepartmentData={displayOpatForDepartmentData}
                        />
                    </ErrorBoundary>
                ) : null}

                {isMobile ? (
                    componentSettings.Calendar ? (
                        <div className="column">
                            <Box
                                width={'100%'}
                                display={'flex'}
                                justifyContent={'center'}
                                alignItems={'center'}
                                flexDirection={'row'}
                            >
                                <Ansattkalender
                                    width={isActuallyMobile ? 399 : 435}
                                    sx={{
                                        maxWidth: isActuallyMobile ? '96%' : '100%',
                                        marginBottom: '15px',
                                    }}
                                    disableSignalR
                                />
                            </Box>
                            {componentSettings.VacationDaysAndTimeBank ? (
                                <ErrorBoundary FallbackComponent={VacationPanelError}>
                                    <VacationPanel
                                        randomNum={randomNum}
                                        planVacation={componentSettings.PlanVacation}
                                    />
                                </ErrorBoundary>
                            ) : (
                                ''
                            )}
                        </div>
                    ) : (
                        ''
                    )
                ) : (
                    ''
                )}

                {!componentSettings.Calendar && componentSettings.VacationDaysAndTimeBank ? (
                    <ErrorBoundary FallbackComponent={VacationPanelError}>
                        <VacationPanel randomNum={randomNum} planVacation={componentSettings.PlanVacation} />
                    </ErrorBoundary>
                ) : (
                    ''
                )}

                {componentSettings.Checklists ? (
                    <CheckListContainer
                        handleShowCheckListTemplate={handleShowCheckListTemplate}
                        checkLists={checkListTemplates}
                    />
                ) : (
                    ''
                )}

                {modules.Vakttorg ? <ShiftHeading /> : null}

                {manualItem ? (
                    <div className="HeadingManualWrapper">
                        <a href={manualItem.Url} className="HeadingManualLinkWrapper">
                            <Panel customClass="PanelHeadingManual">
                                <div>
                                    {loading ? (
                                        <HeadingManualLoader text={p('panel_qman_title')} />
                                    ) : (
                                        <HeadingManual
                                            text={p('panel_qman_title')}
                                            numberUnreadDocs={unreadDocuments}
                                            title={p('tooltip_unread_docs')}
                                        />
                                    )}
                                </div>
                            </Panel>
                        </a>
                    </div>
                ) : (
                    ''
                )}

                {deviationItem ? (
                    <Panel customClass="PanelRegisterBtn">
                        <RegisterDeviationButton link={deviationItem.Url} />
                    </Panel>
                ) : (
                    ''
                )}

                {isMobile && !loading && componentSettings ? (
                    <NewFeaturePanel show={componentSettings.NewShiftSchedule} />
                ) : !componentSettings.Calendar && !componentSettings.NewShiftSchedule && !loading ? (
                    <NewFeaturePanel show={componentSettings.NewShiftSchedule} />
                ) : (
                    ''
                )}

                {filteredMenuList.length > 0 ? (
                    <Panel customClass="MenuPanel">
                        <MenuList menuList={filteredMenuList} />
                    </Panel>
                ) : (
                    ''
                )}

                {isMobile ? (
                    ''
                ) : componentSettings.Calendar && componentSettings.VacationDaysAndTimeBank ? (
                    <ErrorBoundary FallbackComponent={VacationPanelError}>
                        <VacationPanel randomNum={randomNum} planVacation={componentSettings.PlanVacation} />
                    </ErrorBoundary>
                ) : (
                    ''
                )}
            </div>

            {isMobile ? (
                ''
            ) : componentSettings.Calendar ? (
                <div className="column">
                    <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                        <Ansattkalender
                            width={435}
                            sx={{
                                marginTop: '15px',
                                boxShadow: '0 4px 8px #00000029, 0 0 0 1px #0000000a',
                                marginBottom: '15px',
                            }}
                            disableSignalR
                        />
                    </Box>
                    {isMobile ? '' : <NewFeaturePanel show={componentSettings.NewShiftSchedule} />}
                </div>
            ) : (
                ''
            )}

            <div className="column-fullWidth">
                {loading ? (
                    ''
                ) : (
                    <React.Fragment>
                        <ErrorBoundary FallbackComponent={BirthdaysAndAnniversariesError}>
                            <Toast apiMe={apiMe} randomNum={randomNum} />
                        </ErrorBoundary>
                        {componentSettings.BirthdaysAndAnniversaries ? (
                            <ErrorBoundary FallbackComponent={BirthdaysAndAnniversariesError}>
                                <BirthdaysAndAnniversaries apiMe={apiMe} randomNum={randomNum} />
                            </ErrorBoundary>
                        ) : (
                            ''
                        )}
                    </React.Fragment>
                )}
            </div>
        </div>
    );
};

export default Home;
