import { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Skeleton from '@mui/material/Skeleton';
import Button from '@tidsbanken/components/buttons/Button';
import Input from '@tidsbanken/components/Input';
import { usePhrases } from '@tidsbanken/phrases';
import { apiURLAnsatte } from '../../helpers/dataFetcher';
import 'react-simple-keyboard/build/css/index.css';

export type KeyboardLayoutObject = {
    [key: string]: string[];
};

const Keyboard = lazy(() => import('react-simple-keyboard/build/index.modern'));

const ProfilePinEdit = () => {
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [successMessage, setSuccessMessage] = useState<string>('');
    const [notMatchingPin, setNotMatchingPin] = useState<boolean>(false);
    const [wrongCurrentPin, setWrongCurrentPin] = useState<boolean>(false);
    const [pinChange, setPinChange] = useState<boolean>(true);
    const [inputs, setInputs] = useState<any>({});
    const [inputName, setInputName] = useState<any>('oldPin');
    const [keyboardLayout, setKeyboardLayout] = useState<KeyboardLayoutObject>({});
    const keyboard = useRef<any>();
    const p = usePhrases('hjem');

    useEffect(() => {
        const layout = {
            default: ['1 2 3', '4 5 6', '7 8 9', '{bksp} 0'],
        };
        setKeyboardLayout(layout);
    }, []);

    const stringValidator = (pin: string): boolean => {
        const newPin = Number(pin);
        return !!newPin && newPin > 0;
    };

    const handlePinChange = (event: any) => {
        setPinChange(false);
    };

    const handlePinChangeClose = (event: any) => {
        setPinChange(true);
        setInputs('');
        setErrorMessage('');
    };

    const onChange = (event: any) => {
        const inputVal = event.target.value;

        setInputs({
            ...inputs,
            [inputName]: inputVal,
        });

        if (keyboard && keyboard.current) {
            keyboard.current.setInput(inputVal);
        }
    };

    const getInputValue = (inputName: any) => {
        return inputs[inputName] || '';
    };

    const onChangeAll = (inputs: any) => {
        /**
         * Here we spread the inputs into a new object
         * If we modify the same object, react will not trigger a re-render
         */
        setInputs({ ...inputs });
    };

    const handleSubmits = (event: any) => {
        setErrorMessage('');
        setSuccessMessage('');
        setNotMatchingPin(false);
        setWrongCurrentPin(false);

        if (getInputValue('oldPin') === '') {
            setErrorMessage(p('pr_pin_err_invalid'));
            return;
        }

        // handle confirmation and return...
        if (getInputValue('newPin1') !== getInputValue('newPin2')) {
            setErrorMessage(p('pr_pin_err_mismatch'));
            setSuccessMessage('');
            setNotMatchingPin(true);
            return;
        }

        if (!stringValidator(getInputValue('newPin1'))) {
            setErrorMessage(p('pr_pin_err_num'));
            setSuccessMessage('');
            setNotMatchingPin(true);
            return;
        }

        // POST
        fetch(apiURLAnsatte + `pin/${getInputValue('oldPin')}/${getInputValue('newPin1')}`, {
            method: 'POST',
            credentials: 'include',
        })
            .then((res) => {
                if (res.ok) {
                    setInputs('');
                    setErrorMessage('');
                    setSuccessMessage(p('pr_pin_success_saved'));
                    setPinChange(true);
                } else {
                    setErrorMessage(p('pr_pin_err_wrong'));
                    setWrongCurrentPin(true);
                    setSuccessMessage('');
                }
            })
            .catch((e: Error) => {
                setErrorMessage(p('pr_pin_err_wrong'));
                setSuccessMessage('');
                return;
            });
        return;
    };

    return (
        <div className="PinPopup">
            <div className="PinPopupContent">
                <div className="DetailInfoLine">
                    {pinChange ? (
                        <div>
                            <div className="SuccessMessage">{successMessage}</div>
                            <Button
                                className="RegisterBtn ChangePinButton"
                                kind={'info'}
                                label={p('pr_pinpop_btn_change')}
                                onClick={handlePinChange}
                            />
                        </div>
                    ) : (
                        <div>
                            <div className="ErrorMessage">
                                {errorMessage !== '' && (
                                    <div>
                                        <div className="LeftPinPopup">
                                            <FontAwesomeIcon className="faIcon" icon={faExclamationTriangle} />
                                        </div>
                                        <div className="RightPinPopup">{errorMessage}</div>
                                    </div>
                                )}
                            </div>
                            <div className="SuccessMessage">
                                {successMessage !== '' && (
                                    <div>
                                        <div className="LeftPinPopup">
                                            <FontAwesomeIcon className="faIcon" icon={faCheck} />
                                        </div>
                                        <div className="RightPinPopup">{successMessage}</div>
                                    </div>
                                )}
                            </div>
                            <label htmlFor="oldPin">
                                {p('pr_pinpop_current')}
                                <Input
                                    className={wrongCurrentPin ? 'NewPinError' : ''}
                                    id="oldPin"
                                    onChange={onChange}
                                    onFocus={() => setInputName('oldPin')}
                                    type="password"
                                    value={getInputValue('oldPin')}
                                />
                            </label>
                            <label htmlFor="newPin1">
                                {p('pr_pinpop_new')}
                                <Input
                                    className={notMatchingPin ? 'NewPinError' : ''}
                                    id="newPin1"
                                    onChange={onChange}
                                    onFocus={() => setInputName('newPin1')}
                                    type="password"
                                    value={getInputValue('newPin1')}
                                />
                            </label>
                            <label htmlFor="newPin2">
                                {p('pr_pinpop_confirm')}
                                <Input
                                    className={notMatchingPin ? 'NewPinError' : ''}
                                    id="newPin2"
                                    onChange={onChange}
                                    onFocus={() => setInputName('newPin2')}
                                    type="password"
                                    value={getInputValue('newPin2')}
                                />
                            </label>
                            <div className="onScreenKeyboard">
                                <Suspense fallback={<Skeleton variant="rounded" height={185} width={'100%'} />}>
                                    <Keyboard
                                        layout={keyboardLayout}
                                        keyboardRef={(r) => (keyboard.current = r)}
                                        inputName={inputName}
                                        onChangeAll={onChangeAll}
                                    />
                                </Suspense>
                            </div>
                            <div className="PinButtons">
                                <Button
                                    className="RegisterBtn"
                                    kind={'error'}
                                    label={p('pr_pinpop_cancel')}
                                    flat={true}
                                    onClick={handlePinChangeClose}
                                />
                                <Button
                                    className="RegisterBtn"
                                    kind={'info'}
                                    label={p('pr_pinpop_save')}
                                    onClick={handleSubmits}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ProfilePinEdit;
