import { useEffect, useState } from 'react';
import Notification from '@tidsbanken/components/Notification';
import { usePhrases } from '@tidsbanken/phrases';
import format from 'date-fns/format';
import { getAnniversaries } from '../helpers/dataFetcher';
import { ApiMe, Jubile, Jubileer } from '../helpers/dataTypeHelper';
import { hashQueryString } from '../helpers/hashHelper';

type Props = {
    apiMe?: ApiMe;
    randomNum: number;
};

const BirthdaysAndAnniversaries = ({ apiMe, randomNum }: Props) => {
    const [todaysBirthdays, setTodaysBirthdays] = useState<string[]>([]);
    const [todaysAnniversaries, setTodaysAnniversaries] = useState<Jubile[]>([]);

    const p = usePhrases('hjem');

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const todaysDate = new Date();
            const formattedDate = format(todaysDate, 'yyyy-MM-dd');
            const hashedRes = hashQueryString(false, randomNum, apiMe);
            const res: Jubileer = await getAnniversaries(formattedDate, hashedRes);
            setTodaysBirthdays(res.Bursdager);
            setTodaysAnniversaries(res.Jubileer);
        } catch (e) {
            console.error(e);
        }
    };

    const handleAnniversaries = (anniversaries: Jubile[]) => {
        if (anniversaries.length > 0) {
            const anvs = anniversaries.map((ansatt) =>
                ansatt.AarJobbet === 0
                    ? `${p('new_employees_message', `${ansatt.Navn}`)}`
                    : `${p('anniversary_message', `${ansatt.Navn}`, `${ansatt.AarJobbet}`)}`
            );
            const grammaticallySeperatedString = [anvs.slice(0, -1).join(', '), anvs.slice(-1)[0]].join(
                anvs.length < 2 ? '' : ` ${p('and')} `
            );
            return grammaticallySeperatedString;
        } else {
            return;
        }
    };

    const handleBirthdays = (birthdays: string[]) => {
        if (birthdays.length > 0) {
            const bursdager = [birthdays.slice(0, -1).join(', '), birthdays.slice(-1)[0]].join(
                birthdays.length < 2 ? '' : ` ${p('and')} `
            );
            return `${p('birthday_message', `${bursdager}`)}`;
        } else {
            return;
        }
    };

    const birthdaysAndAnniversariesMessage = () => {
        const anniversariesMessage = handleAnniversaries(todaysAnniversaries);
        const birthdayMessage = handleBirthdays(todaysBirthdays);
        if (anniversariesMessage && birthdayMessage) {
            return birthdayMessage + ' ' + anniversariesMessage + `. ${p('congratulations')}`;
        } else if (birthdayMessage) {
            return birthdayMessage;
        } else if (anniversariesMessage) {
            return anniversariesMessage + `. ${p('congratulations')}`;
        } else {
            return;
        }
    };

    return todaysAnniversaries.length > 0 || todaysBirthdays.length > 0 ? (
        <Notification
            label={birthdaysAndAnniversariesMessage()}
            kind={'celebration'}
            className={'birthdaysandanniversaries'}
        />
    ) : null;
};

export default BirthdaysAndAnniversaries;
