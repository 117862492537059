import React from 'react';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import Button from '@tidsbanken/components/buttons/Button';
import { Helpers } from '@tidsbanken/data';
import { usePhrases, WithPhrases } from '@tidsbanken/phrases';
import format from 'date-fns/format';
import isSameDay from 'date-fns/isSameDay';
import subDays from 'date-fns/subDays';
import { autobind } from 'ts-class-autobind';
import { apiSjekkliste, Domain } from './api';
import './CheckListContainer.less';

type P = ReturnType<typeof usePhrases>;
const { oc, cn } = Helpers;

const instansUrl = 'sjekkliste.asp';

const MAX_LOAD = 5;

type Props = {
    handleShowCheckListTemplate: (event?: React.MouseEvent) => void;
    checkLists: Domain.Klasse[];
};

type State = {
    loading: boolean;
    numLoaded: number;
    data?: Domain.Instans[];
    notCheckedIn: boolean;
};

export default class CheckListContainer extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        autobind(this);

        this.state = {
            loading: true,
            numLoaded: 0,
            notCheckedIn: false,
        };

        this.getData();
    }

    async getData() {
        const { numLoaded } = this.state;
        const top = MAX_LOAD + (numLoaded ? 0 : 1);
        const skip = numLoaded * MAX_LOAD + (numLoaded ? 1 : 0);
        try {
            const res = await this.getMineInstanser(top, skip);
            this.setState((ps: State) => {
                const data = ps.data || [];
                data.push(...res.data);
                return {
                    loading: false,
                    numLoaded: ps.numLoaded + 1,
                    data,
                };
            });
        } catch (error) {
            this.setState({
                loading: false,
                notCheckedIn: true,
            });
        }
    }

    async getMineInstanser(top: number, skip: number) {
        return apiSjekkliste.get<Domain.Instans[]>(`Hjem/Mine?top=${top}&skip=${skip}`);
    }

    render() {
        // eslint-disable-next-line prefer-const
        let { data, loading, numLoaded, notCheckedIn } = this.state;

        let es: ((msg: string) => JSX.Element) | undefined = undefined;
        let phrase_for_es: string | undefined = undefined;

        let extraClass = '';
        let showCreateBtn = true;

        if (!this.props.checkLists || this.props.checkLists.length === 0) {
            showCreateBtn = false;
        }

        if (notCheckedIn) {
            es = (msg) => this.renderNotCheckedIn(msg);
            phrase_for_es = 'sl_msg_stamp_in';
            extraClass = '-not-checked-in';
            showCreateBtn = false;
        } else if (!loading && (!data || !data.length)) {
            es = (msg) => this.renderNotFound(msg);
            phrase_for_es = 'sl_msg_no_checklists';
            extraClass = '-not-found';
        }

        if (!data) {
            data = [];
        }

        const hasAllData = data ? data.length <= numLoaded * MAX_LOAD : true;

        return (
            <WithPhrases domain="hjem">
                {(p) => (
                    <div className={cn('SjekklisteHjem', extraClass)}>
                        <div className="-top">
                            <span className="-title">{p('sl_title')}</span>
                            {showCreateBtn ? (
                                <div className="create-button-wrapper">
                                    <Button
                                        kind={'info'}
                                        label={p('sl_btn_create')}
                                        className="create-button-new-checklist"
                                        icon={faPlus}
                                        flat={true}
                                        leftAlignIcon={true}
                                        onClick={(event?: React.MouseEvent) => {
                                            this.props.handleShowCheckListTemplate(event);
                                        }}
                                    />
                                </div>
                            ) : (
                                ''
                            )}
                            <div className="-separator" />
                        </div>
                        <div className="-panels">
                            {es
                                ? es(p(phrase_for_es!))
                                : data!.slice(0, data!.length - (hasAllData ? 0 : 1)).map((instans) => (
                                      <a
                                          key={instans.Id}
                                          href={`${instansUrl}?id=${instans.Id}`}
                                          className="-panel clickable"
                                      >
                                          <div className="Instans">
                                              <div className="-left">
                                                  <div className={cn('status-icon', statuses[value(instans)])}>
                                                      <div className="check-mark" />
                                                  </div>
                                                  <span className="-progress">{getProgress(instans)}</span>
                                              </div>
                                              <div className="-middle">
                                                  <span className="-title">{instans.Tittel}</span>
                                                  <div className="-middle-bottom">
                                                      <div className="-middle-bottom-right">
                                                          {isShared(instans) && [
                                                              <span key="1" className="-sharing">
                                                                  {getSharing(instans, p)}
                                                              </span>,
                                                              <span key="2" className="-bar">
                                                                  —
                                                              </span>,
                                                          ]}
                                                          <span className="-dato">{getDato(instans, p)}</span>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div className="-right">
                                                  <div className="-arrow" />
                                              </div>
                                          </div>
                                      </a>
                                  ))}
                        </div>
                        {!hasAllData && (
                            <a
                                href="#"
                                className="-load-more"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.getData();
                                }}
                            >
                                <span>{p('sl_show_more')}</span>
                            </a>
                        )}
                    </div>
                )}
            </WithPhrases>
        );
    }

    renderLoading = (msg: string): JSX.Element => (
        <div className="Loading">
            <i className="fa fa-spinner fa-spin" />
            <span style={{ marginLeft: 10 }}>{msg}</span>
        </div>
    );

    renderNotCheckedIn = (msg: string): JSX.Element => (
        <div className="-panel -no-shadow NotCheckedIn">
            <img src={require('../../img/es_NoPlay.png')} />
            <span style={{ marginLeft: 10 }}>{msg}</span>
        </div>
    );

    renderNotFound = (msg: string): JSX.Element => (
        <div className="-panel -no-shadow NotFound">
            <img src={require('../../img/es_None.png')} />
            <span style={{ marginLeft: 10 }}>{msg}</span>
        </div>
    );
}

const getProgress = (_instans: Domain.Instans): string => {
    let progress = '';

    if (_instans.Punkter) {
        const done = _instans.Punkter.filter((p) => p.UtfoertAvId !== null && p.UtfoertDato !== null).length;
        const all = _instans.Punkter.length;
        progress = `${done}/${all}`;
    }

    return progress;
};

const isShared = (_instans: Domain.Instans): boolean => {
    const { Ansatte, ArbeidsTyper } = _instans;

    const ansatte = oc(Ansatte)([]);
    const arbeidsTyper = oc(ArbeidsTyper)([]);

    const numAnsatte = ansatte.length;
    const numArbeidsTyper = arbeidsTyper.length;

    return !(numAnsatte === 1 && numArbeidsTyper === 0);
};

const getSharing = (_instans: Domain.Instans, p: P): string => {
    const { Ansatte, Avdelinger, ArbeidsTyper } = _instans;

    const ansatte = oc(Ansatte)([]);
    const avdelinger = oc(Avdelinger)([]);
    const arbeidsTyper = oc(ArbeidsTyper)([]);

    const numAnsatte = ansatte.length;
    const numAvdelinger = avdelinger.length;
    const numArbeidsTyper = arbeidsTyper.length;

    // Ikke returner noe dersom det er en individuell sjekkliste
    if (numAnsatte === 1 && numArbeidsTyper === 0) {
        return '';
    }

    const captionArray = [];

    if (numAnsatte) {
        captionArray.push(
            `${numAnsatte} ${numAnsatte > 1 ? p('sl_caption_employee_multi') : p('sl_caption_employee')}`
        );
    } else if (numAvdelinger) {
        captionArray.push(
            `${numAvdelinger} ${numAvdelinger > 1 ? p('sl_caption_department_multi') : p('sl_caption_department')}`
        );
    } else {
        captionArray.push(p('sl_caption_everyone'));
    }

    if (numArbeidsTyper) {
        captionArray.push(
            `${numArbeidsTyper} ${numArbeidsTyper > 1 ? p('sl_caption_shift_multi') : p('sl_caption_shift')}`
        );
    }

    return captionArray.join(' + ');
};

const getDato = (_instans: Domain.Instans, p: P): string => {
    let dato = '';
    const date = new Date(_instans.Dato);
    const today = new Date();
    const yesterday = subDays(new Date(), 1);

    if (isSameDay(date, today)) {
        dato = p('sl_date_today');
    } else if (isSameDay(date, yesterday)) {
        dato = p('sl_date_yesterday');
    } else {
        dato = format(date, 'dd.MM.yyyy');
    }

    return dato;
};

const statuses = ['not-started', 'started', 'unfinished-done', 'finished-done'];

const value = (_instans: Domain.Instans): Status => {
    const { Utfoert: done, Punkter } = _instans;

    const list = Punkter || [];
    const checked = list.filter((p) => p.UtfoertAvId !== null && p.UtfoertDato !== null).length;
    const finished = checked === list.length;

    return done
        ? finished
            ? Status.FinishedDone
            : Status.UnfinishedDone
        : checked
        ? Status.Started
        : Status.NotStarted;
};

enum Status {
    NotStarted,
    Started,
    UnfinishedDone,
    FinishedDone,
}

export { CheckListContainer, Props, State };
