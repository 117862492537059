import { useEffect, useRef, useState } from 'react';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { usePhrases } from '@tidsbanken/phrases';
import { isAfter, parseISO } from 'date-fns';
import { getMotd } from '../helpers/dataFetcher';
import { ApiMe, Message } from '../helpers/dataTypeHelper';
import { hashQueryString } from '../helpers/hashHelper';
import Motd from './Motd';
import './Toast.less';

type Props = {
    apiMe?: ApiMe;
    randomNum: number;
};

const Toast = ({ apiMe, randomNum }: Props) => {
    const [visible, setVisible] = useState<boolean>(false);
    const [todaysMessage, setTodaysMessage] = useState<Array<Message>>([]);
    const ref = useRef<HTMLDivElement>(null);
    const p = usePhrases('hjem');

    const handleClick = () => {
        if (ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const checkVisible = (element: HTMLElement) => {
        if (element) {
            const rect = element.getBoundingClientRect();
            const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
            return rect.bottom < 0 || rect.top - viewHeight >= 0;
        } else {
            return false;
        }
    };

    const fetchData = async () => {
        try {
            const hashedRes = hashQueryString(false, randomNum, apiMe);
            getMotd(hashedRes).then((data: Message[]) => {
                setTodaysMessage(data);
            });
        } catch (e) {
            console.error(e);
        }
    };

    const checkIfNewMotd = () => {
        if (todaysMessage.length > 0) {
            const datoer = todaysMessage.map((m) => m.OpprettetDato);
            datoer.forEach((date) => {
                const parsedDate = parseISO(date);
                const localStorageItem = localStorage.getItem('ToastNotification');

                if (localStorageItem === null || isAfter(parsedDate, parseISO(localStorageItem))) {
                    localStorage.setItem('ToastNotification', new Date().toISOString());
                    if (checkVisible(ref.current as HTMLElement)) {
                        setVisible(!visible);
                        setTimeout(() => {
                            setVisible(false);
                        }, 5000);
                    }
                }
            });
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        checkIfNewMotd();
    }, [todaysMessage]);

    return (
        <div>
            <div>
                <div className={'Toast ' + (visible ? 'show' : '')}>
                    <span className="ToastContent" onClick={handleClick}>
                        <span>
                            <FontAwesomeIcon className="ExclamationCircle" icon={faExclamationCircle} size={'2x'} />
                            <span>{p('toast_new')}</span>
                        </span>
                        <FontAwesomeIcon className="ExclamationCircle ChevronMotd" icon={faChevronDown} />
                    </span>
                </div>
            </div>
            <div ref={ref}>
                {todaysMessage.map((m) => (
                    <Motd key={m.Id} Message={m} />
                ))}
            </div>
        </div>
    );
};

export default Toast;
