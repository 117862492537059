import * as React from "react";
import Button from "@tidsbanken/components/buttons/Button";
import "./NotLoggedIn.less";
import Logo from "./Logo";
import { usePhrases } from "@tidsbanken/phrases";

type Props = {};

function NotLoggedIn(props: Props) {
    // Cannot use phrases when user is unauthenticated
    // const p = usePhrases("hjem");

    const authUrl = window.location.hostname === "min.tidsbanken.net"
        ? "https://auth.tidsbanken.net"
        : "https://auth-sandkasse.tidsbanken.net";

    return (
        <div className="App">
            <div className="column-fullWidth">
                <div className="NotLoggedIn">
                    <div className="logo-wrapper">
                        <Logo className="logo" />
                    </div>
                    <div className="message-wrapper">
                        <p><b>Du er ikke innlogget</b></p>
                    </div>
                    <div className="button-wrapper">
                        <a href={`${authUrl}`} >
                            <Button
                                label="Logg inn"
                                kind="info"
                            />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NotLoggedIn;

export {
    NotLoggedIn,
};
