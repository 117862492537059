import { useEffect, useState } from 'react';
import { faCalendarPlus } from '@fortawesome/pro-regular-svg-icons/faCalendarPlus';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight';
import { faCircle } from '@fortawesome/pro-solid-svg-icons/faCircle';
import { faRandom } from '@fortawesome/pro-solid-svg-icons/faRandom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@mui/material';
import { usePhrases } from '@tidsbanken/phrases';
import { getAvailableShifts } from '../helpers/dataFetcher';
import { LedigeVakter } from '../helpers/dataTypeHelper';
import ShiftHeadingPanel from './ui/ShiftHeadingPanel';
import Spinner from './ui/Spinner';
import { Panel } from './ui';
import './ShiftHeading.less';

const ShiftHeading = () => {
    const p = usePhrases('hjem');

    const [shifts, setShifts] = useState<LedigeVakter>({
        ShiftChangeFromUser: [
            {
                VaktBytteId: 0,
                PlanId: 0,
                FraAnsattId: 0,
                Fornavn: '',
                Etternavn: '',
            },
        ],
        ShiftApproval: [
            {
                Id: 0,
                Dato: '',
                AnsattId: 0,
                FraKlokken: '',
                TilKlokken: '',
                VaktTorg: false,
                VaktBytteId: 0,
            },
        ],
        AvailableShifts: 0,
        AssignedShifts: [
            {
                Id: 0,
                AnsattId: 0,
                Dato: '',
                FraKlokken: '',
                TilKlokken: '',
                Beskrivelse: '',
            },
        ],
    });
    const [loading, setLoading] = useState<boolean>(true);
    let userShiftChange: string | JSX.Element;
    let shiftApproval: string | JSX.Element;
    let assignedShiftsEl: string | JSX.Element;
    let availableShiftIcon: string | JSX.Element;

    const fetchData = async () => {
        setLoading(true);
        try {
            getAvailableShifts().then((data: LedigeVakter) => {
                setShifts(data);
                setLoading(false);
            });
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    if (shifts.AvailableShifts !== 0) {
        availableShiftIcon = (
            <span className="WrapperIconShift">
                <FontAwesomeIcon className="CircleIconShift" icon={faCircle} size={'2x'} />
                <Tooltip className="Tooltip" title={p('shift_free_shifts')} placement="top" arrow>
                    <strong className="IconNumberShift">{shifts.AvailableShifts}</strong>
                </Tooltip>
            </span>
        );
    } else {
        availableShiftIcon = '';
    }

    if (shifts.ShiftChangeFromUser.length > 1) {
        userShiftChange = (
            <ShiftHeadingPanel
                styleClass="ShiftChange"
                shiftMessage={p('shift_req_multi')}
                faIcon={faRandom}
                link="vakttorg/bytter"
                showChevronIcon={true}
            />
        );
    } else if (shifts.ShiftChangeFromUser.length === 1) {
        userShiftChange = (
            <ShiftHeadingPanel
                styleClass="ShiftChange"
                shiftMessage={p('shift_req_person', shifts.ShiftChangeFromUser[0].Fornavn)}
                faIcon={faRandom}
                link={`vakttorg.asp#vaktbytte/${shifts.ShiftChangeFromUser[0].PlanId}`}
                showChevronIcon={true}
            />
        );
    } else {
        userShiftChange = '';
    }

    if (shifts.ShiftApproval.length > 1) {
        shiftApproval = (
            <ShiftHeadingPanel
                styleClass="ShiftApproval"
                shiftMessage={p('shift_approve_multi')}
                faIcon={faCheck}
                link="vakttorg/godkjenning"
                showChevronIcon={true}
            />
        );
    } else if (shifts.ShiftApproval.length === 1) {
        shiftApproval = (
            <ShiftHeadingPanel
                styleClass="ShiftApproval"
                shiftMessage={p('shift_approve_one')}
                faIcon={faCheck}
                link={`vakttorg.asp#vaktbytte/${shifts.ShiftApproval[0].Id}`}
                showChevronIcon={true}
            />
        );
    } else {
        shiftApproval = '';
    }

    if (shifts.AssignedShifts?.length > 1) {
        assignedShiftsEl = (
            <ShiftHeadingPanel
                styleClass="MultipleAssigned"
                shiftMessage={p('shift_assign_multi')}
                faIcon={faCalendarPlus}
                showChevronIcon={false}
            />
        );
    } else if (shifts.AssignedShifts?.length === 1) {
        assignedShiftsEl = (
            <ShiftHeadingPanel
                styleClass="Assigned"
                shiftMessage={p('shift_assign_one')}
                faIcon={faCalendarPlus}
                link={`vakttorg.asp#${shifts.AssignedShifts[0].Id}`}
                showChevronIcon={true}
            />
        );
    } else {
        assignedShiftsEl = '';
    }

    return (
        <div className="ShiftHeadingWrapper">
            <a className="LinkWrapper" href="vakttorg">
                <Panel
                    customClass={
                        userShiftChange !== '' || shiftApproval !== '' || assignedShiftsEl !== ''
                            ? 'PanelShiftChange'
                            : 'PanelShift'
                    }
                >
                    <div className="HeadingShift">
                        <div className="HeadingShiftContent">
                            <span className="HeadingShiftLinkWrapper" title={p('shift_title')}>
                                {p('shift_title')}
                                {loading ? (
                                    <span className="SpinnerWrapper">
                                        <Spinner width="6%" />
                                    </span>
                                ) : (
                                    availableShiftIcon
                                )}
                                <FontAwesomeIcon className="HeadingShiftChevron" icon={faChevronRight} />
                            </span>
                        </div>
                    </div>
                </Panel>
            </a>
            {loading ? (
                ''
            ) : (
                <div className="LoadingWrapper">
                    {userShiftChange}
                    {shiftApproval}
                    {assignedShiftsEl}
                </div>
            )}
        </div>
    );
};

export default ShiftHeading;
