import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle';
import Notification from '@tidsbanken/components/Notification';
import './Motd.less';

type Props = {
    Message:
    {
        Id: number,
        FraDato: string,
        TilDato: string,
        Tekst: string,
        MeldingsType: number,
        OpprettetDato: string
    }
}

const Motd = ({ Message }: Props) => {

    return (
        <Notification
            label={Message.Tekst}
            kind={"info"}
            className={"TodaysMessage"}
            notificationIcon={faExclamationTriangle}
            notificationSize={"big"}
        />
    );
}

export default Motd;
