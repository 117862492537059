import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { usePhrases } from '@tidsbanken/phrases';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import './ShiftInfoPanel.less';

type Props = {
    shiftInfo: string;
    shiftRole: string;
    shiftStartTime: string;
    shiftEndTime: string;
    shiftTypeColor: string;
    faIcon: IconProp;
    stylePlacement: string;
};

const ShiftInfoPanel = ({
    shiftInfo,
    shiftRole,
    shiftStartTime,
    shiftEndTime,
    shiftTypeColor,
    faIcon,
    stylePlacement,
}: Props) => {
    const p = usePhrases('hjem');

    const errShiftInfo = p('err_department');
    const errShiftRole = p('err_shifttype');

    const isLong = shiftRole.length > 14 || shiftInfo.length > 14;

    return (
        <div className={'ShiftInfoPanel ' + stylePlacement}>
            {
                <div className="InfoWrapper">
                    <FontAwesomeIcon className="Icon" icon={faIcon} size={'2x'} color={shiftTypeColor} />
                    <div className={isLong ? 'TextWrapper Long' : 'TextWrapper'}>
                        <div className="ShiftInfoText">
                            <span className={`ShiftInfoRole ${shiftRole === 'errorShiftRole' ? 'ErrShift' : ''}`}>
                                {shiftRole === 'errorShiftRole' ? errShiftRole : shiftRole},&ensp;
                            </span>
                            <span className={`ShiftInfoLocation ${shiftInfo === 'errorShiftInfo' ? 'ErrShift' : ''}`}>
                                {shiftInfo === 'errorShiftInfo' ? errShiftInfo : shiftInfo}
                            </span>
                        </div>
                        {shiftStartTime && shiftEndTime ? (
                            <span className="ShiftTime">
                                {format(parseISO(shiftStartTime), 'HH:mm')} - {format(parseISO(shiftEndTime), 'HH:mm')}{' '}
                            </span>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            }
        </div>
    );
};

export default ShiftInfoPanel;
