import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { init } from '@tidsbanken/phrases';
import App from './components/App';
import { muiHomeTheme } from './muiTheme';
import './index.less';

init('hjem', 'datetime');

const container = document.getElementById('hovedinnhold') as HTMLElement;
const root = createRoot(container);
root.render(
    <ThemeProvider theme={muiHomeTheme}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </ThemeProvider>
);
