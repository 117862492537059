import * as React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons/faExclamationTriangle";
import { usePhrases } from "@tidsbanken/phrases";
import './HomeError.less'

const HomeError = () => {

    const p = usePhrases("hjem");

    return (
        <div className='HomeError'>
            <div className='ErrorInfo'>
                <FontAwesomeIcon
                    className={"Icon"}
                    icon={faExclamationTriangle}
                    size={'3x'}
                />
                <div className='TextWrapper'>
                    <p className='ErrorText'>{p("err_something")}&ensp;</p>
                    <a className='ErrorText' href='/hjem'>{p("err_try_again")}</a>
                </div>
            </div>
        </div>
    );
};

export default HomeError;
