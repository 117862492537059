import React from "react";
import { IconDefinition as IconDefinitionFree } from "@fortawesome/fontawesome-svg-core";
import { IconDefinition as IconDefinitionPro } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./index.less";

export type Kind = "info" | "success" | "warning" | "error" | "normal"
export type Types = "button" | "submit" | "reset"

/**
 * Properties for the Button component
 *
 * @interface Props
 */
export interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    /** DOM class name */
    className?: string
    /** Icon to be included in the button */
    icon?: IconDefinitionFree | IconDefinitionPro
    /** Label and text for the button */
    label?: string
    /** Custom styling */
    leftAlignIcon?: boolean
    /** Kind of button, different styling */
    kind?: Kind
    /** Type of the button element. Can be any of the HTML button types */
    type?: Types
    /** Flat button styling */
    flat?: boolean
    /** Should only show icon and not text */
    iconButton?: boolean
    /** Should button be circular. Default is false */
    circular?: boolean
}

/**
 * Displays a button.
 * @param props.className DOM class name
 * @param props.icon Icon to be included in the button, expects an imported FA icon
 * @param props.label Label and text for the button
 * @param props.flat Flat button styling
 * @param props.leftAlignIcon Aligning the icon of the button on the left side of the text
 * @param props.kind what kind of preset style. "info", "success", "warning", "error".
 * @param props.type Type of button you would like to have. "button", "reset", "submit".
 * @param props.iconButton Button Without text only an icon.
 * @param props.circular Should the button be circular. Default is false.
 */

const Button: React.FunctionComponent<Props> = ({
    className = "",
    icon,
    label = "Button text",
    flat = false,
    leftAlignIcon = false,
    kind = "normal",
    type = "button",
    iconButton = false,
    circular = false,
    ...restAttributes
}: Props) => {
    const marginLeft: React.CSSProperties = {
        marginLeft: leftAlignIcon ? "0.5rem" : "0rem"
    };
    const marginRight: React.CSSProperties = {
        marginRight: !leftAlignIcon ? "0.5rem" : "0rem"
    };

    // don't add margin if no icon is found
    const margin: React.CSSProperties = (icon && { ...marginLeft, ...marginRight }) || {};

    // warn if use icon without label
    label === "" && icon && !iconButton && console.warn("Should use iconButton attribute");

    return (
        <button
            className={`button ${className}`}
            type={type}
            data-kind={kind}
            data-flat={flat}
            data-circular={circular}
            {...restAttributes}
        >
            {/* have to do this to use icons as import from pro and free */}
            {leftAlignIcon && icon && <FontAwesomeIcon icon={icon } />}

            {!iconButton && !circular && <span style={margin}>{label}</span>}

            {!leftAlignIcon && icon && <FontAwesomeIcon icon={icon } />}
        </button>
    );
};

export default Button;
