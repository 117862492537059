import { useState } from 'react';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Collapse from '@mui/material/Collapse';
import Button from '@tidsbanken/components/buttons/Button';
import { usePhrases } from '@tidsbanken/phrases';
import { Domain } from '../CheckList/api';
import './CheckListElement.less';

type Props = {
    id: string;
    title: string;
    description: string;
    tasks: Domain.KlassePunkt[] | undefined;
    handleCreateCheckList: (id: string) => void;
    errorMessage: string;
    creating: boolean;
};

const CheckListElement = ({ id, title, description, tasks, handleCreateCheckList, errorMessage, creating }: Props) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const p = usePhrases('hjem');

    return (
        <div className="ChecklistElementWrapper">
            <div
                onClick={() => {
                    setIsOpen(!isOpen);
                }}
                className="ExpanderWrapper"
            >
                <div className="Expander">
                    <span className="Title">{title}</span>
                    <FontAwesomeIcon className={`show-more${isOpen ? '-left' : ''}`} icon={faCaretDown} />
                </div>
            </div>
            <Collapse in={isOpen} className="ExpanderContent" unmountOnExit>
                <div className="ExpandedContent">
                    <span className="DescriptionHeading bold">{p('ui_sl_desc')}:</span>
                    <div className="DescriptionContent">
                        <span className="Description">{description !== '' ? description : 'Ingen beskrivelse'}</span>
                    </div>
                    <div className="TasksWrapper">
                        <div className="TaskHeadingWrapper">
                            <span className="TaskHeading">{p('ui_sl_tasks')}:</span>
                        </div>
                        {tasks?.map((task: Domain.KlassePunkt) => (
                            <div className="TaskContent" key={task.Id}>
                                <ul>
                                    <li>{task.Tekst}</li>
                                </ul>
                            </div>
                        ))}
                    </div>
                    <div className="CreateCheckListButton">
                        {creating ? (
                            <div style={{ marginTop: '5px', marginRight: '10px' }}>
                                <span className="fa fa-circle-o-notch fa-spin"></span>
                            </div>
                        ) : (
                            ''
                        )}
                        <Button
                            kind={'info'}
                            label={p('ui_sl_btn_create')}
                            icon={faPlus}
                            leftAlignIcon={true}
                            onClick={() => {
                                handleCreateCheckList(id);
                            }}
                            disabled={creating}
                        />
                    </div>

                    {errorMessage !== '' ? (
                        <div className="ErrorMessageChecklist">
                            <div className="IconTextWrapper">
                                <FontAwesomeIcon
                                    className="CheckListExclamationTriangle"
                                    icon={faExclamationTriangle}
                                />
                                <span className="MessageText">{errorMessage}</span>
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </Collapse>
        </div>
    );
};

export default CheckListElement;
