import * as React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons/faExclamationTriangle";
import { Panel } from "../ui";
import './VacationPanelError.less'
import { usePhrases } from '@tidsbanken/phrases';

const VacationPanelError = () => {

    const p = usePhrases("hjem");

    return (
        <Panel customClass="VacationPanel ErrorState">
            <div className='VacationPanelError'>
                <div className='ErrorInfo'>
                    <FontAwesomeIcon
                        className={"Icon"}
                        icon={faExclamationTriangle}
                        size={'2x'}
                    />
                    <div className='TextWrapper'>
                        <p className='ErrorText'>{p("err_something")}&ensp;</p>
                        <a className='ErrorText' href='/hjem'>{p("err_try_again")}</a>
                    </div>
                </div>
            </div>
        </Panel>
    )
};

export default VacationPanelError;
