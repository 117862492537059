import EmojiPicker, { EmojiClickData, EmojiStyle } from "emoji-picker-react";
import React, {useEffect, useRef} from "react";
import { createPortal } from "react-dom";
import "./style.less";

// remove error private prop error
export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    /** A label for the input field */
    label?: string;
    /** A custom class to be used in styling the component */
    className?: string;
    /** Force onChange to be used */
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    /** Determines if inline style or not */
    inline?: boolean;
    /**
     * Determines type of input field:
     *
     *  text, radio, checkbox, range, password, email, number, date, time, osv...*/
    type?: string;
    /**
     * Determines if you want an awesome Emoji keyboard. 
     */
    emojiKeyboard?: boolean;
    /**
     * Determines if the emoji picker should align to the right.
     */
    emojiKeyboardAlignRight?: boolean;
}

/**
 * Input.
 * @param props.className DOM class name.
 * @param props.label Text to add to label, needs id attribute
 * @param props.onChange An onChange function handler.
 * @param props.inline Determine if inline style or not.
 * @param props.type Desides type of input field.
 * @param props.emojiKeyboard Deisied if you want an awesome Emoji keyboard or not.
 */

// take out what we don't want to include in attributes like label since it don't exist in HTMLInputElement
// className and type is already in use as attributes
const Input = ({
    label,
    className,
    type = "text",
    inline = false,
    emojiKeyboard = false,
    emojiKeyboardAlignRight = false,
    ...attributes
}: Props) => {
    const id = attributes.id;
    const isLabelShowing = label && id;
    const isInputRightSide = isLabelShowing && (type == "radio" || type == "checkbox");
    const [showEmojiKeyboard, setShowEmojiKeyboard] = React.useState(false);
    
    const handleShowEmojiKeyboard = () => {
        setShowEmojiKeyboard(!showEmojiKeyboard);
    };

    const ref = useRef<HTMLInputElement | null>(null);
    const emojiButtonRef = useRef<HTMLButtonElement | null>(null);
    const emojiPickerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (!emojiPickerRef.current) {
            return;
        }
        
        const inputRect = ref.current?.getBoundingClientRect();
        const emojiPickerRect = emojiPickerRef.current?.getBoundingClientRect();
        
        if (!inputRect || !emojiPickerRect) {
            return;
        }
        
        
        const top = inputRect.bottom;
        let left = inputRect.left;
        
        if (emojiKeyboardAlignRight) {
            const emojiButtonRect = emojiButtonRef.current?.getBoundingClientRect();
            if (emojiButtonRect) {
                left = emojiButtonRect.right - emojiPickerRect.width;
            }
        }

        emojiPickerRef.current.style.top = `${top}px`;
        emojiPickerRef.current.style.left = `${left}px`;
    }, [showEmojiKeyboard, emojiPickerRef, emojiKeyboardAlignRight]);

    const handleOnEmojiClick = (emojiObject: EmojiClickData) => {
        if(!ref.current) return;

        setShowEmojiKeyboard(false);
        const oldValue = ref.current.value;
        const caretPosition = ref.current.selectionEnd ?? ref.current.value.length;
        const newValue = oldValue.slice(0, caretPosition) + " " + emojiObject.emoji + oldValue.slice(caretPosition);
        attributes.onChange({ target: { value: newValue } } as React.ChangeEvent<HTMLInputElement>);
        ref.current.value = newValue;
    };

    return (
        <>
            {!isInputRightSide && <label className="Label left-label" htmlFor={id}>{label}</label>}
            <div className={emojiKeyboard && type === "text" ? "input-container-emoji" : "input-container"} > 
                <input
                    {...attributes}
                    ref={ref}
                    className={`${inline &&
                        type != "color" &&
                        type != "radio" &&
                        type != "checkbox" &&
                        type != "range"
                        ? "InputInline"
                        : type == "radio" || type == "checkbox" || type == "range"
                            ? "RadioButton"
                            : type == "color"
                                ? "ColorButton"
                                : "Input"} ${className ?? ""} ${emojiKeyboard && type === "text" ? "emoji-input" : ""}`}
                    type={type}
                />
                {emojiKeyboard && type === "text" && (
                    <div className="emoji-container">
                        <button className="emoji-button" ref={emojiButtonRef} onClick={handleShowEmojiKeyboard} >{showEmojiKeyboard ? "😄" : "😊"}</button>
                        {showEmojiKeyboard && 
                            createPortal(
                                (<div className="emoji-picker-input-wrapper" ref={emojiPickerRef}>
                                    <EmojiPicker
                                        height={400}  
                                        emojiStyle={EmojiStyle.NATIVE} 
                                        emojiVersion="4.0" 
                                        onEmojiClick={handleOnEmojiClick} 
                                    />
                                </div>),
                                document.body
                            )
                        }
                    </div>
                )}
            </div>
            {isInputRightSide && (
                <label className="Label right-label" htmlFor={id}>{label}</label>
            )}
        </>
    );
};

export default Input;
