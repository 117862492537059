import { useContext, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { faCircle } from '@fortawesome/pro-solid-svg-icons/faCircle';
import { faClock } from '@fortawesome/pro-solid-svg-icons/faClock';
import { Alert, Stack } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { usePhrases } from '@tidsbanken/phrases';
import { isAfter, isBefore } from 'date-fns';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { decimalColorToHexColor } from '../helpers/colorHelper';
import { getProjectInfo, getStempleStatus, getTodaysShift, getUsedTime } from '../helpers/dataFetcher';
import { ProsjektInfo, StempleStatus, VaktData } from '../helpers/dataTypeHelper';
import ShiftInfoPanel from './ui/ShiftInfoPanel';
import StampButtonsContainer from './ui/StampButtonsContainer';
import TimeCounter from './ui/TimeCounter';
import MultilineTextFields from './MultilineTextField';
import { NotatContext } from './NotatContext';
import ProjectInfo from './ProjectInfo';
import * as ui from './ui';
import './Today.less';

type Props = {
    handleToBreakClick: () => void;
    setCountdownTime: (time: string) => void;
    breakInfo: any;
    handleFromBreakClick: () => void;
    showHourlyBudget: boolean;
    onClockedInDepartmentId: (departmentId: string | undefined) => void;
};

const Today = ({
    handleToBreakClick,
    setCountdownTime,
    breakInfo,
    handleFromBreakClick,
    showHourlyBudget,
    onClockedInDepartmentId,
}: Props) => {
    const [todaysShift, setTodaysShift] = useState<Array<VaktData>>([]);
    const [projectInfo, setProjectInfo] = useState<ProsjektInfo>({
        Id: 0,
        AnsattId: 0,
        FraKlokken: '',
        ProsjektId: '',
        Prosjekt: { Id: '', Navn: '', Notat: '' },
        ProsjektLinjeId: '',
        ProsjektLinje: { ProsjektId: '', ProsjektLinjeId: '', Navn: '' },
        AktivitetId: '',
        Aktivitet: { Id: '', Navn: '' },
    });
    const [stempleStatus, setStempleStatus] = useState<StempleStatus>({
        ipLimitation: {
            IpFilter: false,
            IpFilterStemple: false,
            IpFilterStempleInn: false,
            IpFilterStempleUt: false,
            IpFilterStempleBytt: false,
            IpFilterTimeliste: false,
            IpFilterRapporter: false,
        },
        ClockedIn: false,
        ActivityStampedOn: {
            Id: 0,
            AnsattId: 0,
            FraKlokken: '',
            Pause1FraKlokken: '',
            Pause1TilKlokken: '',
            Pause2FraKlokken: '',
            Pause2TilKlokken: '',
            ProsjektId: '',
            Prosjekt: { Id: '', Navn: '', Notat: '' },
            ProsjektLinjeId: '',
            ProsjektLinje: { ProsjektId: '', ProsjektLinjeId: '', Navn: '' },
            AktivitetId: '',
            Aktivitet: { Id: '', Navn: '' },
            ArbeidsType: { Id: '', Navn: '', ArtTypeNr3: '', WebBg: '' },
            Avdeling: { Id: 0, Navn: '', ErAvdelingsGruppe: false, HovedAvdelingId: '' },
        },
        ClockInForBreak: false,
        ConfigAns: [],
        MeetingVelferdButtons: {
            MeetingButtons: [],
            VelferdButtons: [],
        },
        ChangeButton: false,
        Timer: 0,
        TimelineProcessed: 0,
        allowedToStampViaKey: true,
        logoutOnDone: true,
        TimeoutAfterClockedIn: {
            Id: 0,
            Navn: '',
            Verdi: '',
        },
    });
    const [loading, setLoading] = useState<boolean>(true);
    const [stampedIn, setStampedIn] = useState<boolean>(false);
    const [breakStatus, setBreakStatus] = useState<any>(breakInfo);
    const [hourlyBudget, setHourlyBudget] = useState<string>('');
    const [progressFiller, setProgressFiller] = useState<number | null>(0);
    const [loadingHourlyBudget, setLoadingHourlyBudget] = useState<boolean>(false);
    const { notat, setNotat, setServerNotat } = useContext(NotatContext);
    const currentDate: Date = new Date();
    const p = usePhrases('hjem');

    const fetchHourlyBudgetData = async (proInfo: ProsjektInfo) => {
        if (showHourlyBudget && proInfo && proInfo.Aktivitet) {
            if (
                proInfo.Aktivitet?.Timer === undefined ||
                proInfo.Aktivitet?.Timer === 0 ||
                proInfo.Aktivitet?.Timer === null
            ) {
                const result = p('td_not_hourly_budget');
                setProgressFiller(null);
                setHourlyBudget(result);
            } else {
                setLoadingHourlyBudget(true);
                const res: number = await getUsedTime();
                const budget = proInfo.Aktivitet?.Timer as number;
                const result = p(
                    'td_hourly_budget',
                    `${res.toFixed(1).replace('.', ',')}`,
                    `${proInfo.Aktivitet?.Timer}`
                );
                setProgressFiller((res / budget) * 100);
                setHourlyBudget(result);
                setLoadingHourlyBudget(false);
            }
        }
    };

    const fetchData = async () => {
        setLoading(true);
        try {
            const [stempleStatus, todayShifts]: [StempleStatus, VaktData[]] = await Promise.all([
                getStempleStatus(),
                getTodaysShift(),
            ]);
            setStempleStatus(stempleStatus);
            if (notat === '' || notat === undefined) {
                setNotat(stempleStatus?.ActivityStampedOn?.Notat ?? '');
                setServerNotat(stempleStatus?.ActivityStampedOn?.Notat ?? '');
            }
            if (
                !stempleStatus?.ActivityStampedOn?.Avdeling.ErAvdelingsGruppe &&
                stempleStatus?.ActivityStampedOn?.Avdeling.HovedAvdelingId
            ) {
                onClockedInDepartmentId(stempleStatus?.ActivityStampedOn?.Avdeling.HovedAvdelingId);
            } else {
                onClockedInDepartmentId(stempleStatus?.ActivityStampedOn?.AvdelingId ?? undefined);
            }
            setTodaysShift(todayShifts);
            setStampedIn(stempleStatus.ClockedIn);
            setCountdownTime(stempleStatus.TimeoutAfterClockedIn ? stempleStatus.TimeoutAfterClockedIn.Verdi : '');
            if (!breakInfo) {
                setBreakStatus(stempleStatus.ActivityStampedOn);
            }
            if (stempleStatus.ClockedIn) {
                getProjectInfo().then((proInfo: ProsjektInfo) => {
                    setProjectInfo(proInfo);
                    fetchHourlyBudgetData(proInfo);
                });
            }
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleNoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNotat(event.target.value);
    };

    const Panel = ui.Panel;
    let timeStamp;

    if (stampedIn) {
        const clockedInTime =
            stempleStatus?.ActivityStampedOn?.FraKlokken && parseISO(stempleStatus.ActivityStampedOn.FraKlokken);
        if (clockedInTime) {
            const formattedTime = format(clockedInTime, 'HH:mm');
            timeStamp = p('td_since')
                ? p('td_since') + ' ' + formattedTime
                : 'Stemplet inn siden' + ' ' + formattedTime;
        }
    } else {
        timeStamp = p('td_not_stamped');
    }

    const shiftInfo = todaysShift.map((shift: VaktData) => {
        return (
            <ShiftInfoPanel
                key={shift.Id}
                stylePlacement={''}
                faIcon={faCircle}
                shiftInfo={shift.Avdeling ? shift.Avdeling?.Navn : 'errorShiftInfo'}
                shiftRole={shift.ArbeidsType ? shift.ArbeidsType?.Navn : 'errorShiftRole'}
                shiftStartTime={shift.FraKlokken}
                shiftEndTime={shift.TilKlokken}
                shiftTypeColor={decimalColorToHexColor(shift?.ArbeidsType?.WebBg)}
            />
        );
    });

    const findLatestShift = (shiftCollection: VaktData[]) => {
        let latestShift: VaktData = shiftCollection[0];
        shiftCollection.forEach((shift: VaktData) => {
            if (isAfter(parseISO(shift.TilKlokken), parseISO(latestShift.TilKlokken))) {
                latestShift = shift;
            }
        });
        return latestShift;
    };

    let todaysShiftHasPassed: boolean = false;
    if (todaysShift.length > 0) {
        todaysShiftHasPassed = isBefore(parseISO(findLatestShift(todaysShift).TilKlokken), currentDate);
    }

    return (
        <div className="Today">
            <Panel customClass="TodayPanel">
                <div className={'HeadingToday ' + (stampedIn ? 'StampedIn' : '')}>
                    <div className="HeadingContentToday" title={p('td_today')}>
                        <div className="HeadingTextToday">
                            <span>{p('td_today') ? p('td_today') : 'I dag'}</span>
                            {loading ? (
                                <Skeleton height={25} width={95} variant="rectangular" />
                            ) : (
                                <TimeCounter
                                    msTime={stempleStatus.Timer}
                                    timeProcessed={stempleStatus.TimelineProcessed}
                                />
                            )}
                        </div>
                    </div>
                </div>

                {stampedIn ? (
                    <div className="StampedIn">
                        <span>{loading ? '' : timeStamp}</span>
                    </div>
                ) : (
                    ''
                )}

                {loading ? (
                    <Skeleton height={20} variant="rectangular" />
                ) : stempleStatus.ActivityStampedOn ? (
                    <ShiftInfoPanel
                        stylePlacement={'ClockedIn'}
                        faIcon={faClock}
                        shiftInfo={
                            stempleStatus.ActivityStampedOn.Avdeling
                                ? stempleStatus.ActivityStampedOn.Avdeling?.Navn
                                : 'errorShiftInfo'
                        }
                        shiftRole={
                            stempleStatus.ActivityStampedOn.ArbeidsType
                                ? stempleStatus.ActivityStampedOn.ArbeidsType?.Navn
                                : 'errorShiftRole'
                        }
                        shiftStartTime={''}
                        shiftEndTime={''}
                        shiftTypeColor={decimalColorToHexColor(stempleStatus?.ActivityStampedOn?.ArbeidsType?.WebBg)}
                    />
                ) : shiftInfo.length > 0 && !todaysShiftHasPassed ? (
                    shiftInfo
                ) : (
                    <div className="NoShiftsToday">
                        {' '}
                        {todaysShiftHasPassed ? (
                            <span>{p('td_shifts_none_multi')}</span>
                        ) : (
                            <span>{p('td_shifts_none')}</span>
                        )}
                    </div>
                )}

                {loading ? (
                    ''
                ) : (
                    <ProjectInfo
                        ProjectInfo={projectInfo}
                        hourlyBudget={hourlyBudget}
                        progressFiller={progressFiller}
                        loading={loadingHourlyBudget}
                    />
                )}
                {!stampedIn ? (
                    <div className="NotClockedInTxt">
                        <p>{loading ? '' : timeStamp}</p>
                    </div>
                ) : (
                    ''
                )}
                {loading ? (
                    ''
                ) : stempleStatus?.ActivityStampedOn && stampedIn ? (
                    <ErrorBoundary
                        FallbackComponent={() => <Alert severity="error">Noe gikk galt med visningen av notat</Alert>}
                    >
                        <MultilineTextFields id={stempleStatus.ActivityStampedOn.Id} />
                    </ErrorBoundary>
                ) : (
                    ''
                )}

                {loading ? (
                    <div className="SkeletonWrapper">
                        <Stack spacing={0.4}>
                            <Skeleton height={90} variant="rectangular" />
                            <Skeleton height={90} variant="rectangular" />
                        </Stack>
                    </div>
                ) : (
                    <StampButtonsContainer
                        breakStatus={breakStatus}
                        stampedIn={stampedIn}
                        stempleStatus={stempleStatus}
                        handleFromBreakClick={handleFromBreakClick}
                        handleToBreakClick={handleToBreakClick}
                    />
                )}
            </Panel>
        </div>
    );
};

export default Today;
