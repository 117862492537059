import { useEffect, useState } from 'react';
import { faPause } from '@fortawesome/pro-solid-svg-icons/faPause';
import { faPlay } from '@fortawesome/pro-solid-svg-icons/faPlay';
import { faStepForward } from '@fortawesome/pro-solid-svg-icons/faStepForward';
import { faStop } from '@fortawesome/pro-solid-svg-icons/faStop';
import { usePhrases } from '@tidsbanken/phrases';
import { apiMeUrl, fetchWithTimeout, getStampInSettings } from '../../helpers/dataFetcher';
import { ActivityStampedOn, ApiMe, StampSettings, StempleStatus } from '../../helpers/dataTypeHelper';
import CustomStampButtonsContainer from '../CustomStampButtonsContainer';
import IpBlockedDisplay from './IpBlockedDisplay';
import StampButton from './StampButton';

type Props = {
    stampedIn: boolean;
    breakStatus: ActivityStampedOn;
    stempleStatus: StempleStatus;
    handleToBreakClick: Function;
    handleFromBreakClick: Function;
};

enum ButtonTypes {
    StampIn = 'StampIn',
    StampOut = 'StampOut',
    StampToPause = 'StampToPause',
    StampToPauseDisabled = 'StampToPauseDisabled',
    Custom = 'CustomStamp',
    Change = 'ChangeStamp',
}

enum IpErrorTypes {
    Info = 'Info',
    Warning = 'Warning',
    Danger = 'Danger',
}

const newStempleInnUrl = 'stemple-inn/';
const newStempleUtUrl = 'stemple-ut';
const newStempleByttUrl = 'stemple-inn/bytt';

const oldStempleInnUrl = 'stemple4.asp?handling=inn';
const oldStempleUtUrl = 'stemple4.asp?handling=ut';
const oldStempleByttUrl = 'stemple4.asp?handling=bytt';

const StampButtonsContainer = ({
    stampedIn,
    breakStatus,
    stempleStatus,
    handleToBreakClick,
    handleFromBreakClick,
}: Props) => {
    const p = usePhrases('hjem');
    const [hasAccessToStempleIn, setHasAccessToStempleIn] = useState<boolean>(false);
    const [hasAccessToStempleUt, setHasAccessToStempleUt] = useState<boolean>(false);

    const fetchData = () => {
        try {
            fetchWithTimeout(apiMeUrl).then((resApiMe: ApiMe) => {
                const myEmployeeGroup = resApiMe.MalId;
                getStampInSettings().then((resStampSettings: StampSettings) => {
                    const employeeGroupsWithSettings = resStampSettings.EmployeeGroupsSettings;
                    if (resStampSettings.StampInVersion === 2 || employeeGroupsWithSettings[myEmployeeGroup]) {
                        setHasAccessToStempleIn(true);
                        setHasAccessToStempleUt(true);
                    }
                });
            });
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    let hadFirstBreak: boolean = false;
    let hadNoBreaks: boolean = false;
    let hadAllBreaks: boolean = false;

    let showCustomBtn = true;
    let showIpDisplay = false;

    const hasKey = document.cookie.match(/\bKey=[^;]+/) !== null;
    const allowedToStampViaKey = hasKey ? stempleStatus.allowedToStampViaKey : true;

    let ipAllowedStamp = stempleStatus.ipLimitation.IpFilterStemple;
    let ipAllowedStampIn = stempleStatus.ipLimitation.IpFilterStempleInn;
    let ipAllowedStampOut = stempleStatus.ipLimitation.IpFilterStempleUt;
    let ipAllowedChange = stempleStatus.ipLimitation.IpFilterStempleBytt;

    if (!ipAllowedStamp) {
        ipAllowedStampIn = false;
        ipAllowedStampOut = false;
        ipAllowedChange = false;
    }

    if (Object.keys(stempleStatus.ipLimitation).length === 0) {
        ipAllowedStampIn = true;
        ipAllowedStampOut = true;
        ipAllowedChange = true;
        ipAllowedStamp = true;
    }

    if (!allowedToStampViaKey) {
        ipAllowedStampIn = false;
        ipAllowedStampOut = false;
        ipAllowedChange = false;
        ipAllowedStamp = false;
    }

    if (stampedIn) {
        hadNoBreaks = !breakStatus.Pause1FraKlokken;
        if (!breakStatus.Pause2FraKlokken && breakStatus.Pause1TilKlokken) {
            hadFirstBreak = true;
        }
        if (breakStatus.Pause2TilKlokken) {
            hadAllBreaks = true;
        }
    }

    // If ip limitation is set on change button, or if ip limitation is set on stampIn when you are clocked out the velferd and meeting buttons are removed
    if (!ipAllowedChange || (!stampedIn && !ipAllowedStampIn)) {
        showCustomBtn = false;
    }

    if (
        (!stampedIn && !ipAllowedStampIn) ||
        (stampedIn && !ipAllowedChange && stempleStatus.ChangeButton) ||
        (stampedIn && !ipAllowedStampOut)
    ) {
        showIpDisplay = true;
    }

    return (
        <div className="StampButtons">
            {stampedIn && ipAllowedChange && stempleStatus.ChangeButton ? (
                <>
                    {hadAllBreaks || hadNoBreaks || hadFirstBreak ? (
                        <StampButton
                            type={ButtonTypes.Change}
                            faIcon={faStepForward}
                            text={p('ui_stamp_change') ? p('ui_stamp_change') : 'Bytt'}
                            link={hasAccessToStempleIn ? newStempleByttUrl : oldStempleByttUrl}
                        />
                    ) : (
                        ''
                    )}{' '}
                </>
            ) : (
                ''
            )}

            {ipAllowedStamp && stempleStatus.ClockInForBreak && stampedIn ? (
                hadAllBreaks ? (
                    <StampButton
                        type={ButtonTypes.StampToPauseDisabled}
                        faIcon={faPause}
                        text={p('ui_stamp_pause_both')}
                        link=""
                    />
                ) : (
                    <StampButton
                        type={ButtonTypes.StampToPause}
                        faIcon={faPause}
                        text={hadNoBreaks || hadFirstBreak ? p('ui_stamp_pause_to') : p('ui_stamp_pause_from')}
                        onClick={hadNoBreaks || hadFirstBreak ? handleToBreakClick : handleFromBreakClick}
                        link=""
                    />
                )
            ) : (
                ''
            )}

            {!stampedIn && ipAllowedStampIn ? (
                <StampButton
                    type={ButtonTypes.StampIn}
                    faIcon={faPlay}
                    link={hasAccessToStempleIn ? newStempleInnUrl : oldStempleInnUrl}
                    text={p('ui_stamp_in') ? p('ui_stamp_in') : 'Stemple inn'}
                />
            ) : (
                ''
            )}

            {stampedIn && (hadAllBreaks || hadNoBreaks || hadFirstBreak) && ipAllowedStampOut ? (
                <StampButton
                    type={ButtonTypes.StampOut}
                    faIcon={faStop}
                    link={hasAccessToStempleUt ? newStempleUtUrl : oldStempleUtUrl}
                    text={p('ui_stamp_out') ? p('ui_stamp_out') : 'Stemple ut'}
                />
            ) : (
                ''
            )}

            {showIpDisplay ? <IpBlockedDisplay text={p('ui_stamp_restricted')} type={IpErrorTypes.Info} /> : ''}

            {(!stampedIn || hadAllBreaks || hadNoBreaks || hadFirstBreak) && showCustomBtn ? (
                <CustomStampButtonsContainer
                    stampedIn={stampedIn}
                    stampStatus={stempleStatus}
                    buttons={stempleStatus.MeetingVelferdButtons}
                />
            ) : (
                ''
            )}
        </div>
    );
};

export default StampButtonsContainer;
