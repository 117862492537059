import * as React from 'react';
import './ShiftHeadingPanel.less';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight';

type Props = {
    shiftMessage: string,
    faIcon: IconProp,
    link?: string,
    styleClass: string,
    showChevronIcon: boolean,
};

const ShiftHeadingPanel = ({ shiftMessage, faIcon, link, styleClass, showChevronIcon }: Props) => {
    return (
        <a href={link} className={"ShiftPanelLinkWrapper " + styleClass}>
        <div className={"ShiftApprovalChange " + styleClass}>
            <span className="ShiftChangeLinkWrapper">
                <div className="MessageIconWrapper">
                    <FontAwesomeIcon
                        className={"CheckIcon Icon" + styleClass}
                        icon={faIcon}
                        size={'2x'}
                    />
                    <p className="ShiftMessage">{shiftMessage}</p></div>
                {showChevronIcon ? <FontAwesomeIcon
                    className="ShiftChangeChevronRight"
                    icon={faChevronRight}
                /> : ''}
            </span>
        </div>
        </a>
    )
}

export default ShiftHeadingPanel;
