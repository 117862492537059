import React, { lazy, Suspense, useContext, useRef, useState } from 'react';
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';
import {
    Box,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Skeleton,
    Snackbar,
    TextField,
    Theme,
    useMediaQuery,
} from '@mui/material';
import { usePhrases } from '@tidsbanken/phrases';
import layout from 'simple-keyboard-layouts/build/layouts/norwegian';
import { timelinjeUrl } from '../helpers/dataFetcher';
import { useReadLocalStorage } from '../hooks/use-localStorage';
import { useOnClickOutsideCustom } from '../hooks/use-onClickOutside';
import TbButton from './ui/Mui/TbButton';
import TextFieldNotat from './ui/Mui/TextFieldNotat';
import { NotatContext } from './NotatContext';

export const patch = (url: string, body?: object, keepalive?: boolean) => {
    const init: RequestInit = {
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'PATCH',
        credentials: 'include',
        body: body ? JSON.stringify(body) : undefined,
        keepalive,
    };
    return fetch(url, init);
};

export type jStorage = {
    'tb-keypadlokal': string;
};

type Props = {
    id?: number;
};

const MultilineTextFields = ({ id }: Props) => {
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('mobile'));
    const { notat, setNotat, setServerNotat, serverNotat } = useContext(NotatContext);
    const [open, setOpen] = useState(false);
    const [saving, setSaving] = useState(false);
    const jStorage = useReadLocalStorage<jStorage>('jStorage');
    const isKeyboardEnabled = jStorage ? jStorage['tb-keypadlokal'] === 'true' : false;
    const [isKeyboardShowing, setIsKeyboardShowing] = useState<boolean>(false);
    const [status, setStatus] = useState({
        state: 'idle',
        message: '',
    });
    const keyboardRef = useRef<any>(null);
    const ref = useRef<any>(null);
    const p = usePhrases('hjem');

    useOnClickOutsideCustom(ref, () => {
        setOpen(false);
    });

    const patchNotat = () => {
        setSaving(true);
        setStatus({ state: 'loading', message: p('saving_note') });
        const url = `${timelinjeUrl}/timelinjeInnstemplet(${id})`;
        patch(url, { Notat: notat }, false)
            .then((res) => {
                if (res.ok) {
                    setStatus({ state: 'success', message: p('success_note') });
                    setServerNotat(notat);
                }
            })
            .catch((err) => {
                console.error(err);
                setStatus({ state: 'error', message: p('error_note') });
            })
            .finally(() => {
                setSaving(false);
                setOpen(false);
                if (isKeyboardEnabled && isKeyboardShowing) {
                    setIsKeyboardShowing(false);
                }
            });
    };

    const handleNoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNotat(event.target.value);
        if (isKeyboardEnabled && isKeyboardShowing && keyboardRef?.current) {
            keyboardRef?.current?.setInput(event.target.value);
        }
    };

    const handleClick = () => {
        if (isKeyboardEnabled && !isKeyboardShowing) {
            setIsKeyboardShowing(true);
        }
    };

    const handleFocus = () => {
        setOpen(true);
    };

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setStatus({ state: 'idle', message: '' });
    };

    const handleCancel = () => {
        setOpen(false);
        setNotat(serverNotat);
        if (isKeyboardEnabled && isKeyboardShowing) {
            setIsKeyboardShowing(false);
        }
    };

    return (
        <Box display={'flex'} flexDirection={'column'} width={'100%'} ref={ref}>
            <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: isMobile ? 'center' : 'flex-start' }}>
                <TextFieldNotat
                    sx={{ width: '100%', mb: 1 }}
                    label={p('note_textfield_label')}
                    multiline
                    maxRows={3}
                    value={notat || ''}
                    onChange={handleNoteChange}
                    onFocus={handleFocus}
                    onClick={handleClick}
                    disabled={saving}
                />
            </Box>
            <Collapse sx={{ mb: 2 }} in={open && !isKeyboardEnabled && !isKeyboardShowing}>
                <TbButton color="error" variant={'outlined'} sx={{ mb: 2 }} onClick={handleCancel}>
                    {p('textfield_cancel')}
                </TbButton>
                <TbButton
                    variant={'outlined'}
                    sx={{ ml: 2, mb: 2 }}
                    onClick={patchNotat}
                    loading={saving}
                    disabled={saving}
                >
                    {p('textfield_save')}
                </TbButton>
            </Collapse>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                autoHideDuration={5000}
                open={status.state === 'success' || status.state === 'error' || status.state === 'loading'}
                message={status.message}
                onClose={handleClose}
                action={<TbButton color="inherit" icon={faTimes} onClick={handleClose} />}
            />
            <TextFieldWithKeyboard
                handleCancel={handleCancel}
                isKeyboardEnabled={isKeyboardEnabled}
                isKeyboardShowing={isKeyboardShowing}
                patchNotat={patchNotat}
                saving={saving}
                handleDialogClose={handleCancel}
            />
        </Box>
    );
};

export default MultilineTextFields;

type TextFieldWithKeyboardProps = {
    patchNotat: () => void;
    handleCancel: () => void;
    isKeyboardEnabled: boolean;
    isKeyboardShowing: boolean;
    saving: boolean;
    handleDialogClose: (event: React.SyntheticEvent | Event, reason?: string) => void;
};

const Keyboard = lazy(() => import('react-simple-keyboard/build/index.modern'));

const TextFieldWithKeyboard = ({
    handleCancel,
    isKeyboardEnabled,
    isKeyboardShowing,
    patchNotat,
    saving,
    handleDialogClose,
}: TextFieldWithKeyboardProps) => {
    const { notat, setNotat } = useContext(NotatContext);
    const [layoutName, setLayoutName] = useState('default');
    const keyboardRef = useRef<any>(null);
    const p = usePhrases('hjem');
    const inputRef = useRef<HTMLInputElement>(null);

    const handleKeyboardChange = (input: string) => {
        setNotat(input);
    };

    const handleNoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNotat(event.target.value);
        if (isKeyboardEnabled && isKeyboardShowing && keyboardRef?.current) {
            keyboardRef?.current?.setInput(event.target.value);
        }
    };

    const handleKeyPress = (button: string) => {
        // dirty hack to set correct init input on keyboard
        if (notat && notat !== '') {
            keyboardRef?.current?.setInput(notat);
        }
        if (button === '{shift}' || button === '{lock}') {
            setLayoutName(layoutName === 'default' ? 'shift' : 'default');
        }
        setTimeout(() => {
            keyboardRef?.current?.setCaretPosition(inputRef?.current?.selectionStart, inputRef?.current?.selectionEnd);
        }, 50);
    };

    return (
        <Dialog open={isKeyboardShowing && isKeyboardEnabled} onClose={handleDialogClose} fullWidth maxWidth="mobile">
            <DialogTitle>{p('dialog_note_title')}</DialogTitle>
            <DialogContent>
                <Box mt={1} mb={2}>
                    <TextField
                        inputRef={inputRef}
                        label={p('note_textfield_label')}
                        multiline
                        rows={3}
                        value={notat || ''}
                        onChange={handleNoteChange}
                        disabled={saving}
                        fullWidth
                    />
                </Box>
                <Suspense fallback={<Skeleton variant="rounded" height={230} width={'100%'} />}>
                    <Keyboard
                        keyboardRef={(r) => (keyboardRef.current = r)}
                        onChange={handleKeyboardChange}
                        onKeyPress={handleKeyPress}
                        layoutName={layoutName}
                        preventMouseDownDefault
                        {...layout}
                    />
                </Suspense>
            </DialogContent>
            <DialogActions>
                <TbButton variant={'text'} sx={{ mb: 1 }} onClick={handleCancel}>
                    {p('textfield_cancel')}
                </TbButton>
                <TbButton
                    variant={'contained'}
                    sx={{ mr: 2, mb: 1 }}
                    onClick={patchNotat}
                    loading={saving}
                    disabled={saving}
                >
                    {p('textfield_save')}
                </TbButton>
            </DialogActions>
        </Dialog>
    );
};
