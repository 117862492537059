import * as React from 'react';
import './StampButton.less'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from "@fortawesome/fontawesome-svg-core";


type Props = {
    faIcon: IconProp,
    type?: string,
    text: string,
    onClick?: Function,
    link: string
};

const StampButton = ({ faIcon, type, text, link, onClick }: Props) => {

    const isLongText = text.length > 30 ? "LongText" : "";

    return (
        <a href={link} onClick={onClick ? (e: React.MouseEvent) => {
            e.preventDefault();
            onClick();
        } : undefined} title={text}
            className={`StampButton ${type} ${isLongText}`}>
            <FontAwesomeIcon
                className="PlayIcon"
                icon={faIcon}
                fixedWidth={true}
            />
            <h3>{text}</h3>
        </a>
    )
};

export default StampButton;

