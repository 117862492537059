import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { usePhrases } from '@tidsbanken/phrases';
import { Panel } from './Panel';
import './NewFeaturePanel.less';

type Props = {
    show: boolean;
};

const NewFeaturePanel = ({ show }: Props) => {
    const p = usePhrases('hjem');

    if (!show) {
        return null;
    }

    return (
        <Panel customClass="FeaturePanel">
            <div className="NewFeatureWrapper">
                <a href={'/plan'} className="NewFeatureLinkWrapper">
                    <div className="Heading">
                        <div className="HeadingContent">
                            <span className="HeadingLinkWrapper" title={p('panel_btn_new_shiftplan')}>
                                {p('panel_btn_new_shiftplan')}
                                <FontAwesomeIcon
                                    className={'HeadingChevron MenuHeadingChevron'}
                                    icon={faChevronRight}
                                />
                            </span>
                        </div>
                    </div>
                </a>
            </div>
        </Panel>
    );
};

export default NewFeaturePanel;
