import React, { useState, useEffect } from 'react';
import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop,
} from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Button from '@tidsbanken/components/buttons/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons/faSpinner';
import { apiURLAnsatte } from '../../helpers/dataFetcher';
import { usePhrases } from '@tidsbanken/phrases';
import './ProfilePictureEdit.less';
import { useDebounceEffect } from '../../helpers/debounceHelper';
import { imgPreview } from '../../helpers/canvasHelper';

type Props = {
    img: string;
    handleEditFinished: Function;
    deleteProfilePicture: Function;
    handleCancel: () => void;
    triggerError: Function;
};

const ProfilePictureEdit = (props: Props) => {
    const [crop, setCrop] = useState<Crop | undefined>();
    const [imgSrc, setImgSrc] = useState(props.img)
    const [croppedImagePreviewURL, setCroppedImagePreviewURL] = useState<string>(props.img);
    const [imageElement, setImageElement] = useState<HTMLImageElement>();
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>();

    const p = usePhrases("hjem");
    const minSize = 150;

    useEffect(() => {
        if (props.img) {
            setImgSrc(props.img);
        }
    }, [props.img]);

    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height
            ) {
                getCroppedImage();
            }
        },
        100,
        [completedCrop],
    );

    const cropImage = () => {
        if (imageElement && completedCrop) {
            const img = imgPreview(imageElement, completedCrop);
            return img;
        }
        return null;
    };

    const getCroppedImage = async () => {
        try {
            const croppedImage = await cropImage();
            if (croppedImage) {

                let reader = new FileReader();
                reader.onloadend = () => {
                    setCroppedImagePreviewURL(reader.result as string);
                };
                reader.readAsDataURL(croppedImage);
            }
        } catch (err) {
            console.error('Feilet på å croppe bilde.', err);
        }
    };

    useEffect(() => {
        props.triggerError(false);

        if (!imageElement) {
            return;
        }
        if (imageElement.naturalWidth <= 150 || imageElement.naturalHeight <= 150) {
            props.triggerError(true);
            props.handleEditFinished();
            return;
        }
    }, [imageElement]);

    const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
        const { width, height } = e.currentTarget;

        if (width > height) {
            setCrop(centerAspectCrop(width , width , 1))
        } else {
            setCrop(centerAspectCrop(width, height , 1))
        }
        setImageElement(e.currentTarget);
    };

    const centerAspectCrop = (
        mediaWidth: number,
        mediaHeight: number,
        aspect: number,
    ) => {
        return centerCrop(
            makeAspectCrop(
                {
                    unit: '%',
                    width: 90,
                },
                aspect,
                mediaWidth,
                mediaHeight,
            ),
            mediaWidth,
            mediaHeight,
        )
    };

    const uploadImage = async () => {
        setIsSaving(true);
        // If a profile picture already exists, delete and remove reference before upload!
        await props.deleteProfilePicture();
        fetch(
            croppedImagePreviewURL
        ).then(res => {
            return res.blob();
        }).then(async blob => {
            let fd = new FormData();
            fd.append('files', blob);
            fetch(apiURLAnsatte + 'profilbilde', {
                method: 'POST',
                credentials: 'include',
                body: fd
            })
                .then(res => res.text())
                .then((imgURL: string) => {
                    setImgSrc(imgURL);
                    props.handleEditFinished(imgURL);
                })
                .catch(err => {
                    setIsSaving(false);
                    console.error(err);
                    setImgSrc('');
                })
        });
    };

    return (
        <div className="PictureContainer">
            <div className='NewImage'>
                {imgSrc ? (
                    <ReactCrop
                        crop={crop}
                        onChange={(_, percentCrop) => setCrop(percentCrop)}
                        onComplete={(c) => setCompletedCrop(c)}
                        circularCrop={true}
                        minWidth={minSize}
                        minHeight={minSize}
                        keepSelection={true}
                        aspect={1}
                    >
                        <img
                            alt="Crop me"
                            src={imgSrc}
                            onLoad={onImageLoad}
                        />
                    </ReactCrop>
                ) : (
                    <div>
                        {p("pr_pic_no_pic")}
                    </div>
                )}
            </div>
            <div className="ImageActions">
                <div className="ImageActionsRight">
                    <FontAwesomeIcon
                        icon={faSpinner}
                        spin={true}
                        className={'SavingIcon' + (isSaving ? ' Show' : '')}
                    />

                    <Button
                        kind={'error'}
                        label={p("pr_btn_cancel")}
                        flat={true}
                        disabled={!imgSrc || isSaving}
                        onClick={props.handleCancel}
                    />
                    <Button
                        className="SaveImageButton"
                        kind={'success'}
                        label={p("pr_pic_btn_save")}
                        onClick={uploadImage}
                        disabled={!imgSrc || isSaving}
                    />
                </div>
            </div>
        </div>
    );
};

export default ProfilePictureEdit;