import { ReactNode } from 'react';
import React from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonProps, CircularProgress } from '@mui/material';
import './TbButton.less';

type TbButtonProps = ButtonProps & {
    loading?: boolean;
    icon?: React.ReactNode | IconDefinition | boolean;
    circular?: boolean;
};

const TbButton = ({ children, loading, icon, circular, ...attrs }: TbButtonProps) => {
    let iconElement: React.ReactNode;

    if ((icon as IconDefinition)?.iconName) {
        iconElement = <FontAwesomeIcon icon={icon as IconDefinition} />;
    } else {
        iconElement = icon as ReactNode;
    }

    circular = !!icon && circular !== false;

    return (
        <Button
            {...attrs}
            className={'tb-button' + (attrs.className ? ' ' + attrs.className : '')}
            data-icon={icon ? 'true' : undefined}
            data-size={attrs.size}
            data-loading={loading}
            data-circular={circular}
        >
            {loading && <CircularProgress style={{ position: 'absolute' }} size="1.5rem" color="inherit" />}
            <div className="tb-button-children">{iconElement || children}</div>
        </Button>
    );
};

export default TbButton;
