import Hash from "object-hash";
import { ApiMe } from "./dataTypeHelper";

export const hashQueryString = (
    useEmpId: boolean,
    randomNum: number,
    apiMe?: ApiMe
) => {
    const resultToHash = apiMe
        ? `${apiMe.FirmaId}_${useEmpId ? apiMe.Id : apiMe.Avdeling}`
        : randomNum
    return Hash(resultToHash, { algorithm: 'md5', encoding: 'base64' });
}
