import axios from 'axios';
import { Models, Api } from '@tidsbanken/data';

import Domain = Models.Sjekkliste.Domain;
import Foreign = Models.Sjekkliste.Foreign;
import Join = Models.Sjekkliste.Join;

const apiSjekkliste = axios.create({
    baseURL: Api.url({
        repo: 'sjekkliste',
        path: '',
    }),
    withCredentials: true,
});

type OData<T> = {
    value: T[];
};

export {
    apiSjekkliste,
    Domain,
    Foreign,
    Join,
    OData,
};
