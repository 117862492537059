import { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import setDefaultOptions from 'date-fns/setDefaultOptions';
import useDateLocale from '../hooks/useDateLocale';
import CheckListTemplate from './CheckList/CheckListTemplate';
import HomeError from './ErrorComponents/HomeError';
import NotLoggedIn from './NotLoggedIn/NotLoggedIn';
import Home from './Home';
import { NotatProvider } from './NotatContext';
import Profile from './Profile';
import './App.less';

export default function App() {
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(true);
    const randomNum = Math.random();
    const tbLocale = useDateLocale();
    setDefaultOptions({ locale: tbLocale, firstWeekContainsDate: 4, weekStartsOn: 1 });

    const ProtectedRouteOutlet: () => JSX.Element = () => {
        return isLoggedIn ? <Outlet /> : <Navigate replace to="/hjem/ikke-innlogget" />;
    };

    const handleNotLoggedIn = (loggedIn: boolean) => {
        setIsLoggedIn(loggedIn);
    };

    const HomeComponent = () => {
        return (
            <ErrorBoundary FallbackComponent={HomeError}>
                <Home handleNotLoggedIn={handleNotLoggedIn} randomNum={randomNum} />
            </ErrorBoundary>
        );
    };

    const ProfileComponent = () => {
        return <Profile handleNotLoggedIn={handleNotLoggedIn} />;
    };

    const CheckListTemplateComponent = () => {
        return <CheckListTemplate />;
    };

    return (
        <NotatProvider>
            <Routes>
                <Route path="/" element={<Navigate replace to="/hjem" />} />
                <Route path="/hjem" element={<ProtectedRouteOutlet />}>
                    <Route path="" element={<HomeComponent />} />
                </Route>
                <Route path="/hjem/profil" element={<ProtectedRouteOutlet />}>
                    <Route path="" element={<ProfileComponent />} />
                </Route>
                <Route path="/hjem/manuell-sjekkliste" element={<ProtectedRouteOutlet />}>
                    <Route path="" element={<CheckListTemplateComponent />} />
                </Route>
                <Route
                    path="/hjem/ikke-innlogget"
                    element={!isLoggedIn ? <NotLoggedIn /> : <Navigate replace to="/hjem" />}
                />
            </Routes>
        </NotatProvider>
    );
}
