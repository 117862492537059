import React from 'react';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight';
import { faCircle } from '@fortawesome/pro-solid-svg-icons/faCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@mui/material/Tooltip';
import Spinner from './Spinner';
import './Heading.less';

type Props = {
    text: string;
    onClick?: () => void;
    numberUnreadDocs?: number;
    href?: string;
    title?: string;
};

type TextElementProps = {
    text: string;
};

type NumberElementProps = {
    numberUnreadDocs?: number;
    title?: string;
};

type ButtonElementProps = {
    text: string;
    onClick: () => void;
    numberUnreadDocs?: number;
    href?: string;
};

type LinkElementProps = {
    href: string;
    title: string;
};

export function Heading(props: Props) {
    return (
        <div className="Heading">
            {props.onClick ? (
                <ButtonElement text={props.text} onClick={props.onClick} numberUnreadDocs={props.numberUnreadDocs} />
            ) : props.href ? (
                <LinkElement href={props.href} title={props.text} />
            ) : (
                <HeadingTextElement text={props.text} />
            )}
        </div>
    );
}

export function HeadingManual(props: Props) {
    return (
        <div className="HeadingManual" style={{ borderBottom: 'none' }}>
            <div className="HeadingManualContent">
                <span className="HeadingManualLinkWrapper" title={props.text}>
                    {props.text}
                    {props.numberUnreadDocs ? (
                        <HeadingNumberElement numberUnreadDocs={props.numberUnreadDocs} title={props.title} />
                    ) : (
                        <></>
                    )}
                    <FontAwesomeIcon className="HeadingManualChevron" icon={faChevronRight} />
                </span>
            </div>
        </div>
    );
}

export function HeadingManualLoader(props: Props) {
    return (
        <div className="HeadingManual" style={{ borderBottom: 'none' }}>
            <div className="HeadingManualContent">
                <span
                    className="HeadingManualLinkWrapper"
                    onClick={(e?: React.MouseEvent) => {
                        e?.preventDefault();
                        props.onClick ? props.onClick() : '';
                    }}
                    title={props.text}
                >
                    {props.text}
                    <span className="SpinnerWrapper">
                        <Spinner width="6%" />
                    </span>
                    <FontAwesomeIcon className="HeadingManualChevron" icon={faChevronRight} />
                </span>
            </div>
        </div>
    );
}

function HeadingNumberElement(props: NumberElementProps) {
    return (
        <span className="WrapperIcon">
            <FontAwesomeIcon className="CircleIcon" icon={faCircle} size={'2x'} />
            <Tooltip className="Tooltip" title={props.title} placement="top" arrow>
                <strong className="IconNumber">{props.numberUnreadDocs}</strong>
            </Tooltip>
        </span>
    );
}

function HeadingTextElement(props: TextElementProps) {
    return (
        <div className="HeadingContent" title={props.text}>
            <div className="HeadingText">
                <span>{props.text}</span>
            </div>
        </div>
    );
}

function ButtonElement(props: ButtonElementProps) {
    return (
        <div className="HeadingContent">
            <a
                className="HeadingLinkWrapper"
                href="#"
                onClick={(e?: React.MouseEvent) => {
                    e?.preventDefault();
                    props.onClick();
                }}
                title={props.text}
            >
                {props.text}
                <FontAwesomeIcon className="HeadingChevron" icon={faChevronRight} />
            </a>
        </div>
    );
}

export function LinkElement(props: LinkElementProps) {
    return (
        <div className="HeadingContent">
            <a className="HeadingLinkWrapper" href={props.href} title={props.title}>
                {props.title}
                <FontAwesomeIcon className={'HeadingChevron'} icon={faChevronRight} />
            </a>
        </div>
    );
}
