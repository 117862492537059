[
    {
        "language": "Afrikaans",
        "id": "af",
        "flag": "South-Africa"
    },
    {
        "language": "Albanian",
        "id": "sq",
        "flag": "Albania"
    },
    {
        "language": "Arabic",
        "id": "ar",
        "flag": "Saudi-Arabia"
    },
    {
        "language": "Azerbaijani",
        "id": "az",
        "flag": "Azerbaijan"
    },
    {
        "language": "Basque",
        "id": "eu",
        "flag": "Spain"
    },
    {
        "language": "Bengali",
        "id": "bn",
        "flag": "Bangladesh"
    },
    {
        "language": "Belarusian",
        "id": "be",
        "flag": "Belarus"
    },
    {
        "language": "Bulgarian",
        "id": "bg",
        "flag": "Bulgaria"
    },
    {
        "language": "Catalan",
        "id": "ca",
        "flag": "Spain"
    },
    {
        "language": "Chinese Simplified",
        "id": "zh-CN",
        "flag": "China"
    },
    {
        "language": "Chinese Traditional",
        "id": "zh-TW",
        "flag": "China"
    },
    {
        "language": "Croatian",
        "id": "hr",
        "flag": "Croatia"
    },
    {
        "language": "Czech",
        "id": "cs",
        "flag": "Czech-Republic"
    },
    {
        "language": "Danish",
        "id": "da",
        "flag": "Denmark"
    },
    {
        "language": "Dutch",
        "id": "nl",
        "flag": "Netherlands"
    },
    {
        "language": "English",
        "id": "en",
        "flag": "United-Kingdom"
    },
    {
        "language": "Esperanto",
        "id": "eo",
        "flag": ""
    },
    {
        "language": "Estonian",
        "id": "et",
        "flag": "Estonia"
    },
    {
        "language": "Filipino",
        "id": "tl",
        "flag": "Philippines"
    },
    {
        "language": "Finnish",
        "id": "fi",
        "flag": "Finland"
    },
    {
        "language": "French",
        "id": "fr",
        "flag": "France"
    },
    {
        "language": "Galician",
        "id": "gl",
        "flag": ""
    },
    {
        "language": "Georgian",
        "id": "ka",
        "flag": "Georgia"
    },
    {
        "language": "German",
        "id": "de",
        "flag": "Germany"
    },
    {
        "language": "Greek",
        "id": "el",
        "flag": "Greece"
    },
    {
        "language": "Gujarati",
        "id": "gu",
        "flag": "India"
    },
    {
        "language": "Haitian Creole",
        "id": "ht",
        "flag": "Haiti"
    },
    {
        "language": "Hebrew",
        "id": "iw",
        "flag": "Israel"
    },
    {
        "language": "Hindi",
        "id": "hi",
        "flag": "Fiji"
    },
    {
        "language": "Hungarian",
        "id": "hu",
        "flag": "Hungary"
    },
    {
        "language": "Icelandic",
        "id": "is",
        "flag": "Iceland"
    },
    {
        "language": "Indonesian",
        "id": "id",
        "flag": "Indonesia"
    },
    {
        "language": "Irish",
        "id": "ga",
        "flag": "Ireland"
    },
    {
        "language": "Italian",
        "id": "it",
        "flag": "Italy"
    },
    {
        "language": "Japanese",
        "id": "ja",
        "flag": "Japan"
    },
    {
        "language": "Kannada",
        "id": "kn",
        "flag": ""
    },
    {
        "language": "Korean",
        "id": "ko",
        "flag": "South-Korea"
    },
    {
        "language": "Latin",
        "id": "la"
    },
    {
        "language": "Latvian",
        "id": "lv",
        "flag": "Latvia"
    },
    {
        "language": "Lithuanian",
        "id": "lt",
        "flag": "Lithuania"
    },
    {
        "language": "Macedonian",
        "id": "mk",
        "flag": "Macedonia"
    },
    {
        "language": "Malay",
        "id": "ms",
        "flag": "Malaysia"
    },
    {
        "language": "Maltese",
        "id": "mt",
        "flag": "Malta"
    },
    {
        "language": "Norwegian (bokmål)",
        "id": "nb",
        "flag": "Norway"
    },
    {
        "language": "Norwegian (nynorsk)",
        "id": "nn",
        "flag": "Norway"
    },
    {
        "language": "Persian",
        "id": "fa",
        "flag": "Iran"
    },
    {
        "language": "Polish",
        "id": "pl",
        "flag": "Poland"
    },
    {
        "language": "Portuguese",
        "id": "pt",
        "flag": "Portugal"
    },
    {
        "language": "Romanian",
        "id": "ro",
        "flag": "Romania"
    },
    {
        "language": "Russian",
        "id": "ru",
        "flag": "Russia"
    },
    {
        "language": "Serbian",
        "id": "sr",
        "flag": "Serbia"
    },
    {
        "language": "Slovak",
        "id": "sk",
        "flag": "Slovakia"
    },
    {
        "language": "Slovenian",
        "id": "sl",
        "flag": "Slovenia"
    },
    {
        "language": "Spanish",
        "id": "es",
        "flag": "Spain"
    },
    {
        "language": "Swahili",
        "id": "sw",
        "flag": "Uganda"
    },
    {
        "language": "Swedish",
        "id": "sv",
        "flag": "Sweden"
    },
    {
        "language": "Tamil",
        "id": "ta",
        "flag": "Sri-Lanka"
    },
    {
        "language": "Telugu",
        "id": "te",
        "flag": "India"
    },
    {
        "language": "Thai",
        "id": "th",
        "flag": "Thailand"
    },
    {
        "language": "Turkish",
        "id": "tr",
        "flag": "Turkey"
    },
    {
        "language": "Ukrainian",
        "id": "uk",
        "flag": "Ukraine"
    },
    {
        "language": "Urdu",
        "id": "ur",
        "flag": "Pakistan"
    },
    {
        "language": "Vietnamese",
        "id": "vi",
        "flag": "Vietnam"
    },
    {
        "language": "Welsh",
        "id": "cy",
        "flag": "United-Kingdom"
    },
    {
        "language": "Yiddish",
        "id": "yi",
        "flag": "Bosnia-and-Herzegovina"
    }
]
